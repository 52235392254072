export function resizeIframe() {
    let timer = null;
    let duration = 10;

    function postMessage() {
        const styles = window.getComputedStyle(window.document.documentElement);

        const height = Math.ceil(Number(styles.height.replace("px", "")));
        const width = Math.ceil(Number(styles.width.replace("px", "")));

        if (timer) {
            clearTimeout(timer);
            duration += 20;
        }
        timer = setTimeout(() => {
            window.parent.postMessage({ height, width }, window.parent.location.origin);
        }, duration);
    }

    window.addEventListener("DOMContentLoaded", postMessage);

    window.addEventListener("load", postMessage);

    window.addEventListener("transitionend", postMessage);

    window.addEventListener("resize", postMessage);
}
