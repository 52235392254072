import { AxiosRequestConfig, AxiosResponse } from "axios";
import { getAxiosInstances } from "../../../../utils/AppUtils";
import AxiosActions from "../../AxiosActions";

export const getProductDataById = (id: number, config?: any) => {
    return AxiosActions.get(getAxiosInstances().V2, `/health/test-product/${id}`, { withCredentials: true, ...config }, true, false);
};

export const postNewTestProduct = (dto: any): Promise<AxiosResponse> => {
    return AxiosActions.post(
        getAxiosInstances().V2,
        `/health/test-product`,
        dto,
        { withCredentials: true, headers: { "Content-Type": "multipart/form-data" } },
        true,
    );
};

export const updateExistingTestProduct = (testId: number, dto: any): Promise<AxiosResponse> => {
    return AxiosActions.put(getAxiosInstances().V2, `/health/test-product/${testId}`, dto, { withCredentials: true }, true);
};

export const deleteExistingTestProduct = (testProductId: number): Promise<AxiosResponse> => {
    return AxiosActions.delete(getAxiosInstances().V2, `/health/test-product/${testProductId}`, {}, true);
};

export const getTestProductsCustomConfig = (config: AxiosRequestConfig, spinner = true): Promise<AxiosResponse> => {
    return AxiosActions.get(getAxiosInstances().V2, `/health/test-product`, config, spinner);
};

export const getAllTestProducts = (
    // type: OrganizationRole.HEALTH_PROVIDER | OrganizationRole.GO_TO_MARKET,
    // testProductName: string,
    // page: number,
    // size: number,
    config: any = {},
    spinner: boolean = false,
): Promise<AxiosResponse> => {
    return AxiosActions.get(
        getAxiosInstances().V2,
        `/health/test-product`, // ?type=${type}&name=${testProductName}&page=${page}&size=${size}
        {
            withCredentials: true,
            ...config,
        },
        spinner,
        false,
    );
};
export const getOrganizationShipments = (testProductCode: string, kitKey: string, spinner = false) => {
    return AxiosActions.get(getAxiosInstances().V2, `organization/test-product/${testProductCode}/kit/${kitKey}/shipments`, {}, spinner, true);
};
