import { useEffect, useRef } from "react";

export function useHideGoogleAddressOptionsOnScroll(placeRef: any) {
    const posRef = useRef();

    function closeAutocompleteContextMenu() {
        const googlePlacesContainer: HTMLDivElement | null = document.querySelector(".pac-container");
        if (googlePlacesContainer) {
            const topPos = placeRef.current.getBoundingClientRect().top;
            if (posRef.current && placeRef.current && Number(Math.abs(posRef.current - topPos) > 20)) {
                placeRef.current?.blur();
                googlePlacesContainer.style.display = "none";
            }
        }
    }

    function handleFocus() {
        const { top } = placeRef.current.getBoundingClientRect();
        posRef.current = top;
    }

    useEffect(() => {
        window.addEventListener("scroll", closeAutocompleteContextMenu, true);
        return () => {
            window.removeEventListener("scroll", closeAutocompleteContextMenu, true);
        };
    }, []);
    return handleFocus;
}
