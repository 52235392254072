import unionBy from "lodash/unionBy";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Item } from "../../views/privatePages/ProductGroupManagement/ProductGroupManagementTypes";

export interface PartnersState {
    partners: Item[];
}

const initialState: PartnersState = {
    partners: [],
};

const partnersSlice = createSlice({
    initialState,
    name: "partnersSlice",
    reducers: {
        fetchPartners(state, action: PayloadAction<Item[]>) {
            return { ...state, partners: action.payload };
        },
        addPartners(state, action: PayloadAction<Item[]>) {
            return { ...state, partners: unionBy(state.partners, action.payload, "id") };
        },
        updatePartners(state, action: PayloadAction<Item[]>) {
            return { ...state, partners: action.payload };
        },
        removePartners(state) {
            return { ...state, partners: state.partners.filter((product) => product.checked === false) };
        },
    },
});

export const { addPartners, fetchPartners, removePartners, updatePartners } = partnersSlice.actions;
export default partnersSlice.reducer;
