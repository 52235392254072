/* eslint camelcase: ["error", {allow: ["long_name", "short_name", "administrative_area_level_1", "administrative_area_level_2", "postal_code", "address_components", "formatted_address", "html_attributions", "place_id"]}] */
import { ContainerProps } from "@mui/material";
import { GoogleAddressCountries, WorkflowType, OrganizationContactTypes, QualitativeResultValues } from "../appEnums/AppEnums";

export interface HealthProviderOrganizationOption {
    id: number;
    name: string;
}

export interface ErrorMessage {
    id: string;
    defaultMessage: string;
}

export interface GeneralMessage extends ErrorMessage {}

export interface TextField {
    value: string;
    error: boolean;
    errorMsg: ErrorMessage;
}

export interface TextFieldDate {
    value: any;
    error: boolean;
    errorMsg: ErrorMessage;
}
export interface AutoCompleteOption {
    label: string;
    id: number;
}

export interface AutocompleteNameOption extends Omit<AutoCompleteOption, "label"> {
    name: string;
}

export interface SelectField {
    value: AutoCompleteOption | null;
    error: boolean;
    errorMsg: ErrorMessage;
}

export interface SelectFieldNameOption extends Omit<SelectField, "value"> {
    value: AutocompleteNameOption | null;
}

export interface AutocompleteNameCreateOption extends AutocompleteNameOption {
    createdValue?: string;
}

export interface SelectFieldNameCreateOption extends Omit<SelectField, "value"> {
    value: AutocompleteNameCreateOption | null;
}

export interface NumberField {
    value: number;
    error: boolean;
    errorMsg: ErrorMessage;
}

export interface CheckBoxField {
    value: boolean;
    error: boolean;
}

export interface NpiPersonalOption {
    id: number;
    npi: number;
    providerFirstName: string;
    providerLastName: string;
    providerBusinessMailingAddressCityName: string;
    providerBusinessMailingAddressStateName: string;
}

export interface NpiPersonalOptionSelectField extends Omit<TextField, "value"> {
    value: NpiPersonalOption | null;
}

export interface NpiCompanyOption extends NpiCompanyOptionSelect {
    providerOrganizationName: string;
    providerBusinessMailingAddressCityName: string;
    providerBusinessMailingAddressStateName: string;
    providerFirstLineBusinessMailingAddress: string;
}

export interface NpiCompanyOptionSelect {
    id: number;
    npi: number;
}

export interface NpiCompanyOptionSelectField extends Omit<TextField, "value"> {
    value: NpiCompanyOption | null;
}

export interface NpiCompanyCreateOption extends NpiCompanyOption {
    createdValue?: string;
}

export type JSONString = string;

export interface JSONStringOption {
    id: number;
    name: string;
}

export interface JSONStringOptionNullable {
    id: number | null;
    name: string | null;
}

export interface JSONStringSpecimenOption extends JSONStringOption {
    description: string;
}

export interface JSONStringSpecimenOptionNullable extends JSONStringOptionNullable {
    description: string | null;
}

export type JSONValue = string | number | boolean | JSONObject;

export interface JSONObject {
    [x: string]: JSONValue;
}

export interface JSONStringObject {
    [x: string]: string;
}

export enum TemperatureUnits {
    FAHRENHEIT = "°F",
    CELSIUS = "°C",
}

export interface JSONStringKitOption extends JSONStringOption {
    description: string;
}

export interface JSONStringMedicalCodeOption extends JSONStringOption {
    description: string;
}

export interface JSONStringLocationOption extends JSONStringOption {
    locationOrderCode: string;
    locationCapacity: number | null;
}

export interface JSONStringQuestionOption extends JSONStringOption {
    questionText: string;
    type: string;
    allowableAnswers: ChooseOneAnswer | ChooseManyAnswer | LengthAnswer | MassAnswer | NumberAnswer | TemperatureAnswer | TextAnswer | VolumeAnswer;
}

export interface Range {
    from: number;
    to: number;
    unitOfMeasure: string;
}

export interface ChooseOneAnswer {
    chooseOne: string[];
}

export interface ChooseManyAnswer {
    chooseMany: string[];
}

export interface LengthAnswer {
    length: Range;
}

export interface MassAnswer {
    mass: Range;
}

export interface NumberAnswer {
    number: Range;
}

export interface TemperatureAnswer {
    temperature: Range;
}

export interface TextAnswer {
    text: object;
}

export interface VolumeAnswer {
    volume: Range;
}

export interface CollectionSpecificationPayload {
    id: number;
    name: string;
    preferred: boolean;
    minimumVolume: string;
    temperatureUnit: string;
    causesForRejection: string;
    patientPreparation: string;
    transportTemperature: number;
    collectionInstructions: string;
    transportAndStorageInstructions;
    collectionInstructionsLink: string;
    roomTemperature: number;
    frozenTemperature: number;
    refrigeratedTemperature: number;
    specimenCategory: string;
    preferredSpecimen: JSONStringOption;
    alternativeSpecimens: JSONStringOption[];
    collectionDevices: JSONStringOption[];
}
export interface LTCTestOffered {
    id?: number;
    labOrderCode: string;
    name: string;
    allowInsurancePay: boolean;
    skipPanelLabOrderCode?: boolean;
    defaultPricePerTest: number;
    defaultCurrency: { id: number; symbol: string; name: string };
    expectedTurnaroundTimeHrsMin: number;
    expectedTurnaroundTimeHrsMax: number;
    alternativeNames: string[];
    testDescription: string;
    clinicalUtilityUse: string;
    methodology: string;
    limitations: string;
    isPanel: boolean;
    testOfferedComponents: JSONStringOption[];
    isActive: boolean;
    isUnique: boolean;
    hasReflexes: boolean;
    governmentReporting: boolean;
    defaultIcd: JSONStringMedicalCodeOption[];
    cptCode: JSONStringMedicalCodeOption[];
    transportContainer: string;
    releaseDate: string;
    retirementDate: string;
    testOfferedReflexes: JSONStringOption[];
    reflexDescription: string;
    reflexesAllowed: boolean;
    allLocationsUnless: boolean;
    isPhysicalApproval: boolean;
    isPrescriptionRequired: boolean;
    unavailableInRelation: JSONStringOption[];
    weeklyCapacity: number;
    therapeuticCategory: string;
    specialization: string;
    methodologyCategory: string;
    locationAvailability: JSONStringLocationOption[];
    askAtOrderEntry: JSONStringQuestionOption[];
    academicTest: { id: number; name: string; replacementMode: string } | null;
    collectionSpecifications: CollectionSpecificationPayload[];
}

export interface TestProductTemplatingObject extends Omit<TestProductDataInterface, "labTestOrdered" | "supplyProductOrdered"> {
    labTestOrdered: {
        id: number;
        name: string;
        orgId: number;
        orgName: string;
        detailedData?: TestOfferedTemplatingObject;
    } | null;
    supplyProductOrdered: {
        id: number;
        name: string;
        orgId: number;
        orgName: string;
        detailedData?: SupplyProduct;
        img: string | undefined;
    } | null;
}

export interface ResultDefinition {
    id: number;
    name: string;
    resultType: string;
    resultDescription: string;
    componentTestId: number;
    loinc: { id: number; name: string; description: string }[];
    lowerRange: number;
    upperRange: number;
    uom: string;
}

export interface TestOfferedTemplatingObject extends LTCTestOffered {
    resultDefinitions?: ResultDefinition[];
}

export interface BomLineItem {
    id: number;
    name: string;
    details: string;
    quantity: number;
    cost: number;
    supplier: string;
    manufacturer: string;
    supplyProductId: number;
}

export interface SupplyProduct {
    id?: number;
    name: string;
    active: boolean;
    samplingKits: JSONStringOption[];
    manufacturer: { id: number; name: string };
    description: string;
    productCategory: string;
    sku: string;
    upcCode: string;
    otherProductCodes: any;
    cost: number;
    currency: { id: number; symbol: string; name: string };
}

export interface TestProductDataInterface {
    id?: number;

    // section:Product components
    name: string;
    productCategory: string;
    labTestOrdered: {
        id: number;
        name: string;
        orgId: number;
        orgName: string;
    };
    productCode: string;
    supplyProductOrdered: {
        id: number;
        name: string;
        orgId: number;
        orgName: string;
        img: string | undefined;
    };
    upccode: string;
    otherProductCodes: JSONStringObject;
    defaultPrescribingOrganization: { id: number; name: string } | null;

    // section:Product journey
    upFrontPaymentRequired: boolean;
    allowInsurancePayment: boolean;
    healthProviderAnswersQuestionnaire: boolean;
    healthProviderApprovesReportRelease: boolean;
    patientAnswersQuestionnaire: boolean;
    patientCollectsSample: boolean;
    collectPatientConsent: boolean;
    consentContent: string;
    defaultOutboundCarrier: { id: number; name: string } | null;
    defaultOutboundShipmentType: { id: number; name: string } | null;

    // section: journey / Product Journey
    workflowTemplateGroup: { id: number; name: string } | null;

    // result report
    publishedResultReportFileURL: string | null;

    // section:Marketing
    price: number;
    currency: {
        id: number;
        symbol: string;
        name: string;
    };
    description: string;
    frequentlyAskedQuestions: string;
    active: boolean;

    activationDate: string;
    deactivationDate: string;

    billingType: [string];
    governmentReporting: boolean;
    inboundCarrier: string;
    outboundCarrier: string;
    reflexesOptOutAllowed: boolean;
    remoteProctored: boolean;
    sample: boolean;
    tenantId: number;
    testProductInitiatedBy: string;
    approvedByOrganization: HealthProviderOrganizationOption;
    unavailableIn: JSONStringOption[] | null;
    private: boolean;
    availableToOrganizations: { id: number; name: string }[];
    primaryImageURL: string;
    compiledVersionURL?: string;
    useCustomTemplate?: boolean;

    isActive?: boolean;
    isRemoteProctored?: boolean;
    isSample?: boolean;
    isPrivate?: boolean;
    labId?: number;
    supplierId?: number;
    offeredByOrganization?: { id: number; name: string; tenantId: number } | null;
    publishedTemplateURL?: string | null;
    exampleResultReportFileURL?: string | null;
    useInstructionsFileURL?: string | null;
}

export interface CustomFile extends Blob {
    path: string;
    lastModified: number;
    lastModifiedDate: Date;
    name: string;
    size: number;
    type: string;
    webkitRelativePath: string;
}

export interface Currency {
    id: number;
    name: string;
    symbol: string;
}

export interface OrganizationFile {
    id: number;
    name: string;
    currenVersionId: number;
    publicUrl: string;
}

export interface ContactPerson {
    id: number;
    email: string;
    firstName: string;
    middleName: string;
    lastName: string;
    phoneNumber: string;
    contactTypes: OrganizationContactTypes[];
}

export interface HeadquarterAddressMetadata {
    addressLine1: string;
    addressLine2: string;
    cityName: string;
    country: string;
    county: string;
    fullAddress: string;
    id: number;
    isValidated: boolean;
    lat: number;
    lon: number;
    name: string;
    personLocationType?: string;
    phoneNumber: string;
    postalCode: string;
    stateName: string;
    type: string;
}

export interface OrganizationMetadata {
    id: number;
    name: string;
    type: string[];
    organizationTenantId: number;
    corporateEmailDomain: string;
    companyTaxId: string;
    duns: string;
    labClia: string;
    personContacts: ContactPerson[] | null;
    npi: { id: number; npiNumber: number } | null;
    shortOrganizationName: string;
    headquarterAddress?: HeadquarterAddressMetadata;
    labDirector: string;
    primaryCorporateEmail: string;
    primaryCorporatePhone: string;
    primaryCorporatePhoneRegion: string;
}

export interface SysTenantConfiguration {
    boiId: number;
    tenantId: number;
    tenantName: string;
    subdomain: string;
    primaryColor: string | null;
    secondaryColor: string | null;
    applicationName: string;
    facilityName: string;
    tenantLogo: OrganizationFile | null;
    tenantDarkLogo: OrganizationFile | null;
    tenantTitleIcon: OrganizationFile | null;
    tenantDarkTitleIcon: OrganizationFile | null;
    supportEmail: string;
    supportPhone: string;
    supportPhoneRegion: string;
    supportPageURL: string;
    organization: OrganizationMetadata;
    tagLine?: string;
    enabledInTenant: boolean;
    hipaaCoveredEntity: boolean;
}

export interface AddressVerified {
    country: boolean;
    city: boolean;
    zipCode: boolean;
    fullAddress: boolean;
    // addressLine1: boolean;
}

export const addressVerifiedKeys = ["country", "fullAddress", "city", "zipCode"];

export interface Place {
    address_components: GoogleAddressComponent[];
    formatted_address: string;
    geometry: { location: { lat: () => number; lng: () => number } };
    html_attributions: any[];
    place_id: string;
}

export interface GoogleAddressComponent {
    long_name: string;
    short_name: string;
    types: string[];
}

export interface PlaceAddress {
    lat: number;
    lon: number;
    formatted_address: string;
    country: GoogleAddressComponent;
    administrative_area_level_1: GoogleAddressComponent;
    administrative_area_level_2: GoogleAddressComponent;
    locality: GoogleAddressComponent;
    postal_code: GoogleAddressComponent;
    route: GoogleAddressComponent;
    street_number: GoogleAddressComponent;
}

export const defaultPlaceAddress: PlaceAddress = {
    lat: 999.9,
    lon: 999.9,
    formatted_address: "",
    country: {
        long_name: "",
        short_name: "",
        types: ["country"],
    },
    administrative_area_level_1: {
        long_name: "",
        short_name: "",
        types: ["administrative_area_level_1"],
    },
    administrative_area_level_2: {
        long_name: "",
        short_name: "",
        types: ["administrative_area_level_2"],
    },
    locality: {
        long_name: "",
        short_name: "",
        types: ["locality"],
    },
    postal_code: {
        long_name: "",
        short_name: "",
        types: ["postal_code"],
    },
    route: {
        long_name: "",
        short_name: "",
        types: ["route"],
    },
    // eslint-disable-next-line camelcase
    street_number: {
        long_name: "",
        short_name: "",
        types: ["street_number"],
    },
};

export const googleAddressCountries = [GoogleAddressCountries.UNITED_STATES_OF_AMERICA, GoogleAddressCountries.CANADA, GoogleAddressCountries.AUSTRALIA];

export type StringFunction = () => string;
export type RouteToIntendedTransition = StringFunction | null;

export enum OrganizationPatient {
    Self = "SELF",
    FamilyMember = "FAMILY",
}
export type CreatePatientMode = OrganizationPatient.Self | OrganizationPatient.FamilyMember;
export interface StandardPhoneNumberFormat {
    number: string;
    dialCode: string;
    region: string;
}
export interface InsurancePrecedence {
    insuranceId: number;
    precedenceType: string;
    type: string;
}

export interface WorkflowTemplateGroupOption {
    id: number;
    name: string;
}

export interface ReadFileResult {
    resultName: string;
    attachedFileName: string | null;
    resultId: number | null;
    custom: boolean;
    notes?: string;
    resultDescription?: string;
}

export interface FileResult extends Omit<ReadFileResult, "attachedFileName"> {
    attachedFile: { attachedFileName: string; id: number } | null;
    file: File | null;
    custom: boolean;
}

export interface ReadQuantitativeResult {
    resultId: number | null;
    resultValue: string | null;
    lowThreshold: string | number;
    highThreshold: string | number;
    resultName: string;
    uom: string;
    custom: boolean;
    notes?: string;
    resultDescription?: string;
}

export interface QuantitativeResult extends Omit<ReadQuantitativeResult, "uom" | "resultValue" | "lowThreshold" | "highThreshold"> {
    uom: { id: number; label: string };
    resultValue: number | null;
    lowThreshold: number;
    highThreshold: number;
    custom: boolean;
    notes?: string;
}

export interface ReadQualitativeResult {
    resultValue: string | null;
    resultName: string;
    resultId: number | null;
    custom: boolean;
    notes?: string;
    resultDescription?: string;
}

export interface QualitativeResult extends ReadQualitativeResult {}

interface TestOfferedResult {
    qualitativeDefinitions: QualitativeResult[];
    quantitativeDefinitions: QuantitativeResult[];
    fileDefinitions: FileResult[];
}

export interface TestOfferedResultArray {
    testOfferedResults: TestOfferedResult[];
}

export interface QualitativeResultsPayload {
    id?: number;
    value?: string;
    resultType?: string;
    name?: string;
    notes?: string;
}

export interface QuantitativeResultPayload extends QualitativeResultsPayload {
    //  id?: number;
    //  value: string;
    // resultType?: string;
    // name?: string;
    upperRange?: number;
    lowerRange?: number;
    unitOfMeasure?: string;
}
export interface FileResultPayload {
    data: {
        id?: number;
        name: string;
        notes?: string;
        delete?: boolean;
    };
    file?: File;
}

export interface TestResultData {
    id: number;
    lowerRange: number;
    name: string;
    resultDescription: string;
    resultType: string;
    uom: string;
    updated: string;
    upperRange: number;
    value: any;
    notes: string;
}

export interface TestResult {
    name: string;
    id: number | null;
    resultDefinitions: TestResultData[];
    initResultDefinitionsMap: { [x: string]: TestResultData };
    addCustomResult?: boolean;
}

interface Patient {
    birthDate: string;
    name: string;
    id: number;
}

interface TestProduct {
    id: number;
    name: string;
}
export interface DetailsInterface {
    patient: Patient;
    testProduct: TestProduct;
    isUat: boolean;
}

export interface StyledContainerProps extends ContainerProps {
    isSubdomain?: boolean;
}

export interface AuthUserData {
    id: number;
    keycloakId?: number | null;
    username: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    enabled?: boolean;
    emailVerified?: boolean;
    isMta: boolean;
    roles: string[];
    groups?: any[]; // string ??
    timeZone: string;
    locale: string;
    preferredLanguage?: string;
    birthDate: string;
    possibleEnglishCommunication: boolean;
    personId: number;
    race?: string;
    ethnicity?: string;
    middleName?: string;
    namePrefix?: string;
    nameSuffix?: string;
    otherNames?: string;
    titles?: string;
    cityOfBirth?: string;
    biologicalGender?: string; // enum for gender ??
    genderIdentity?: string; // enum for gender ??
    hashCode?: any;
    locations?: any;
    receiveNotifications?: any;
    tenants: { id: number; name: string }[];
    tenantContext?: { id: number; name: string } | null;
    acceptedTerms?: boolean;
    systemAdministrator?: boolean;
    customerSupport?: boolean;
    mfaEnabled: boolean;
    totpEnabled: boolean;
    signature: { id: number; name: string } | null;
}

export interface TenantData {
    id: number;
    name: string;
    tsCreated?: string;
    tsUpdated?: string;
    updatedByUser?: string;
    updatedByProcess?: string;
    temporary?: false;
    timeZone?: string;
    isDeleted?: false;
    organization: {
        id: number;
        type: string[];
        capabilities: string[];
        organizationTenantId: number;
    };
}

export interface InsuranceInfoData {
    id: number;
    insuranceCompany: {
        insuranceCompanyName: string;
        insuranceCompanyId: number;
    };
    subscriberId: string;
    groupId?: string;
    cancellationDate?: string;
    expirationDate?: string;
    effectiveDate: string;
    insuranceType: string;
    insurancePrecedence: string;
    insuranceRelationship?: string;
    subscriberFirstName?: string;
    subscriberLastName?: string;
    email?: string;
    phoneNumber?: string;
    birthDate?: string;
    insuranceFrontImage?: {
        imageId: number;
        name: string;
    };
    insuranceBackImage?: {
        imageId: number;
        name: string;
    };
}

export type QualitativeResultMap<T> = {
    [x in QualitativeResultValues]: T;
};

export interface PatientLocation {
    id: number;
    name: string;
    type: string;
    country: string;
    fullAddress: string;
    addressLine1: string;
    addressLine2: string;
    cityName: string;
    county: string;
    stateName: string;
    lat: number;
    lon: number;
    postalCode: string;
    countryCode: string;
    validated: boolean;
    primary: boolean;
    active: boolean;
    personLocationType: boolean;
}

export interface PatientData {
    // dateOfBirth: string;
    // email: string;
    // ethnicity: string;
    // firstName: string;
    // id: number;
    // lastName: string;
    // locationAddressCity: string;
    // locationAddressStreet: string;
    // locationCountry: string;
    // locationCounty: string;
    // locationPostalCode: string;
    // phone: string;
    // race: string;
    // sex: string;
    // genderIdentity: string;
    // sexualOrientation: string;

    // expected response from 4948 and 4897 //

    id: number;
    username: string;
    firstName: string;
    middleName: string;
    lastName: string;
    phoneNumber: string | null;
    email: string;
    enabled: boolean;
    preferredLanguage: string;
    birthDate: string;
    race: string;
    ethnicity: string;
    genderIdentity: string;
    sexualOrientation: string;
    biologicalGender: string;
    namePrefix: string | null;
    nameSuffix: string | null;
    otherNames: string | null;
    titles: string | null;
    location: PatientLocation | null;
    cityOfBirth: string | null; // may not need
    signature: { id: number; name: string } | null;
    profilePicture: { id: number; name: string } | null;
    socialSecurityNumber: string;
}

export interface ActiveOrderEntry {
    name: string;
    id: number;
    isUat: boolean;
    testProduct: {
        name: string;
        id: number;
        createdBy: {
            name: string;
            id: number;
            tenantId: number;
        };
    };
    organizationName: string;
    node: {
        name: string;
        id: number;
        type: string;
        status: string;
        created: string;
        updated: string;
        stepInfo: {
            key: string;
            type: string;
            label: string;
            canSubmit: boolean;
            completedDataPreview: boolean;
            data: any;
        };
    };
    created: string;
    updated: string;
    patient: any;
    testOffered: {
        id: number;
        name: string;
        isPanel: boolean;
        specialization: string[];
        therapeuticCategory: string[];
    };
    supplyProduct: any;
    primaryImageURL: string;
}

export interface WorkflowTemplate {
    id: number;
    name: string;
    type: WorkflowType;
    created: string;
    updated: string;
    updatedBy: string;
    createdBy: {
        id: number;
        firstName: string;
        lastName: string;
    };
}
