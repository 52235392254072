import { FC } from "react";
import { useParams } from "react-router-dom";
import TestProductDetails from "../TestProductsPlaceOrder/components/TestProductDetails";

interface TestProductDetailsPageProps {}

const TestProductDetailsPage: FC<TestProductDetailsPageProps> = () => {
    const { testProductId } = useParams();

    return <TestProductDetails testproductId={Number(testProductId)} showAddToOrderBtn={false} />;
};

export default TestProductDetailsPage;
