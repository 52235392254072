import { cloneDeep } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { AxiosResponse } from "axios";
import * as gql from "gql-query-builder";
import { AddressVerified, addressVerifiedKeys, googleAddressCountries, PlaceAddress } from "../../../../../utils/appInterfaces/AppInterfaces";
import { AddressLocation, PayloadFormatAddress } from "./GoogleAutocompleteAddressTypes";
import { AppAddressCountries, GoogleAddressCountries } from "../../../../../utils/appEnums/AppEnums";
import { BO_CORE_DEFAULT_NUMBER_VALUE, BO_CORE_DEFAULT_VALUE, isEmptyStringValue } from "../../../../../utils/AppUtils";
import { graphQlRequest } from "../../../../../config/axios/apis/graphql/graphql";

const googleAddressCountriesArr = [GoogleAddressCountries.UNITED_STATES_OF_AMERICA, GoogleAddressCountries.CANADA, GoogleAddressCountries.AUSTRALIA];

export const defaultAddressLocation: AddressLocation = {
    id: BO_CORE_DEFAULT_NUMBER_VALUE,
    addressLine1: "",
    addressLine2: "",
    fullAddress: "",
    city: "",
    country: "",
    county: "",
    isVerified: false,
    lat: 999.9,
    lon: 999.9,
    state: "",
    type: "",
    zipCode: "",
};

export const getAddressValuesFromPlacesApi = (locationValue: AddressLocation, placeAddressValue: PlaceAddress): { location: AddressLocation } => {
    const locationCopy = cloneDeep(locationValue);

    locationCopy.addressLine1 = `${placeAddressValue.street_number.long_name} ${placeAddressValue.route.long_name}`;
    locationCopy.addressLine2 = "";
    locationCopy.city = placeAddressValue.locality.long_name;
    locationCopy.county = placeAddressValue.administrative_area_level_2.long_name;
    locationCopy.lat = placeAddressValue.lat;
    locationCopy.lon = placeAddressValue.lon;
    locationCopy.state = placeAddressValue.administrative_area_level_1.short_name;
    locationCopy.zipCode = placeAddressValue.postal_code.long_name;
    locationCopy.fullAddress = placeAddressValue.formatted_address;
    return { location: locationCopy };
};

export const checkNewGoogleAddressIsVerified = (locationValue: AddressLocation, placeAddressValue: PlaceAddress): boolean => {
    const newAddressVerified: AddressVerified = {
        country: true,
        city: true,
        zipCode: true,
        fullAddress: true,
    };
    const keys: string[] = addressVerifiedKeys;
    keys.forEach((key: string) => {
        if (key === "country") {
            let selectedCountry: string | undefined;
            if (locationValue[key]) {
                selectedCountry = locationValue[key].trim();
                switch (selectedCountry) {
                    case AppAddressCountries.UNITED_STATES_OF_AMERICA:
                        newAddressVerified[key] = placeAddressValue[key]?.long_name === GoogleAddressCountries.UNITED_STATES_OF_AMERICA;
                        break;
                    case AppAddressCountries.CANADA:
                        newAddressVerified[key] = placeAddressValue[key]?.long_name === GoogleAddressCountries.CANADA;
                        break;
                    case AppAddressCountries.AUSTRALIA:
                        newAddressVerified[key] = placeAddressValue[key]?.long_name === GoogleAddressCountries.AUSTRALIA;
                        break;
                    default:
                        break;
                }
            } else {
                newAddressVerified[key] = false;
            }
        }
        if (key === "city") {
            if (placeAddressValue.locality.long_name.length > 0) {
                newAddressVerified[key] = locationValue[key].trim().length > 0 && locationValue[key].trim() === placeAddressValue.locality.long_name;
            }
        }
        if (key === "zipCode") {
            newAddressVerified[key] = locationValue[key].trim().length > 0 && locationValue[key].trim() === placeAddressValue.postal_code.long_name;
        }
        if (key === "fullAddress") {
            newAddressVerified[key] = locationValue[key].trim().length > 0 && locationValue[key].trim() === placeAddressValue.formatted_address;
        }
    });

    return newAddressVerified.country && newAddressVerified.city && newAddressVerified.zipCode && newAddressVerified.fullAddress;
};

export const createNewLocationRecord = (locationValue: AddressLocation, addressValue: string, locationName: string): Promise<AxiosResponse> => {
    const defaultVerified =
        (locationValue.lat !== 999.9 && locationValue.lon !== 999.9 && locationValue.isVerified) ||
        (googleAddressCountriesArr.includes(locationValue.country as GoogleAddressCountries) && locationValue.isVerified);

    const addressLine1 = locationValue.addressLine1?.trim().length > 0 ? locationValue.addressLine1?.trim() : addressValue.trim();
    const fullAddress = locationValue.fullAddress.trim().length > 0 ? locationValue.fullAddress.trim() : addressValue.trim();

    const locationUpdateValue = {
        type: locationValue.type,
        name: `${locationName}-(${uuidv4()})`,
        country: locationValue.country,
        addressLine1,
        addressLine2: `${locationValue.addressLine2.trim() || BO_CORE_DEFAULT_VALUE}`,
        cityName: `${locationValue.city?.trim() || BO_CORE_DEFAULT_VALUE}`,
        county: `${locationValue.county.trim() || BO_CORE_DEFAULT_VALUE}`,
        stateName: `${locationValue.state.trim() || BO_CORE_DEFAULT_VALUE}`,
        postalCode: `${locationValue.zipCode.trim() || BO_CORE_DEFAULT_VALUE}`,
        lat: locationValue.lat,
        lon: locationValue.lon,
        countryCode: "",
        isValidated: defaultVerified,
        fullAddress,
    };

    const query = gql.mutation([
        {
            operation: "InsertLocationRecord",
            variables: {
                record: {
                    value: locationUpdateValue,
                    type: "GraphQLInsertLocationInput",
                },
            },
            fields: [{ record: ["id"] }],
        },
    ]);
    return graphQlRequest(query, false);
};

export const createNewPatientAddress = (addressValue: string, locationValue: AddressLocation): PayloadFormatAddress | null => {
    const defaultVerified =
        (locationValue.lat !== 999.9 && locationValue.lon !== 999.9 && locationValue.isVerified) ||
        (!googleAddressCountries.includes(locationValue.country as GoogleAddressCountries) && locationValue.isVerified);
    const defaultAddressLine1 = locationValue.addressLine1?.trim().length > 0 ? locationValue.addressLine1.trim() : addressValue?.trim();
    const defaultAddressLine2 = locationValue.addressLine2?.trim().length > 0 ? locationValue.addressLine2.trim() : BO_CORE_DEFAULT_VALUE;
    const defaultCityName = locationValue.city.trim().length > 0 ? locationValue.city.trim() : BO_CORE_DEFAULT_VALUE;
    const defaultCounty = locationValue.county?.trim().length > 0 ? locationValue.county.trim() : BO_CORE_DEFAULT_VALUE;
    const defaultPostalCode = locationValue.zipCode?.trim().length > 0 ? locationValue.zipCode.trim() : BO_CORE_DEFAULT_VALUE;
    const defaultStateName = locationValue.state?.trim().length > 0 ? locationValue.state.trim() : BO_CORE_DEFAULT_VALUE;
    const defaultFullAddress = locationValue.fullAddress?.trim().length > 0 ? locationValue.fullAddress.trim() : addressValue?.trim();
    const addressAsString = addressValue ?? "";
    if (isEmptyStringValue(addressAsString)) {
        return null;
    }

    // TODO: implement this logic in the actual google autocomplete address and its supporting utilities. Requires an app-wide refactor

    let countryCode;

    switch (locationValue.country) {
        case AppAddressCountries.CANADA:
            countryCode = "ca";
            break;
        case AppAddressCountries.UNITED_STATES_OF_AMERICA:
            countryCode = "us";
            break;
        case AppAddressCountries.AUSTRALIA:
            countryCode = "au";
            break;
        default:
            countryCode = BO_CORE_DEFAULT_VALUE;
    }

    return {
        addressLine1: defaultAddressLine1,
        addressLine2: defaultAddressLine2,
        cityName: defaultCityName,
        country: locationValue.country,
        countryCode,
        county: defaultCounty,
        lat: locationValue.lat,
        lon: locationValue.lon,
        name: `${addressValue}-(${uuidv4()})`,
        postalCode: defaultPostalCode,
        stateName: defaultStateName,
        type: "Personal",
        personLocationType: [locationValue.type],
        validated: defaultVerified,
        fullAddress: defaultFullAddress,
    };
};

export const createNewOrganizationAddress = (addressValue: string, locationValue: AddressLocation): PayloadFormatAddress => {
    const defaultVerified =
        (locationValue.lat !== 999.9 && locationValue.lon !== 999.9 && locationValue.isVerified) ||
        (!googleAddressCountries.includes(locationValue.country as GoogleAddressCountries) && locationValue.isVerified);
    const defaultAddressLine1 = locationValue.addressLine1.trim().length > 0 ? locationValue.addressLine1.trim() : addressValue.trim();
    const defaultAddressLine2 = locationValue.addressLine2.trim().length > 0 ? locationValue.addressLine2.trim() : BO_CORE_DEFAULT_VALUE;
    const defaultCityName = locationValue.city?.trim().length > 0 ? locationValue.city?.trim() : BO_CORE_DEFAULT_VALUE;
    const defaultCounty = locationValue.county.trim().length > 0 ? locationValue.county.trim() : BO_CORE_DEFAULT_VALUE;
    const defaultPostalCode = locationValue.zipCode.trim().length > 0 ? locationValue.zipCode.trim() : BO_CORE_DEFAULT_VALUE;
    const defaultStateName = locationValue.state.trim().length > 0 ? locationValue.state.trim() : BO_CORE_DEFAULT_VALUE;
    const defaultFullAddress = locationValue.fullAddress.trim().length > 0 ? locationValue.fullAddress.trim() : addressValue.trim();

    return {
        addressLine1: defaultAddressLine1,
        addressLine2: defaultAddressLine2,
        cityName: defaultCityName,
        country: locationValue.country,
        countryCode: BO_CORE_DEFAULT_VALUE,
        county: defaultCounty,
        lat: locationValue.lat,
        lon: locationValue.lon,
        name: `${addressValue}-(${uuidv4()})`,
        postalCode: defaultPostalCode,
        stateName: defaultStateName,
        type: locationValue.type,
        validated: defaultVerified,
        fullAddress: defaultFullAddress,
    };
};
