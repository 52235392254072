import { Box, Container, useTheme } from "@mui/material";
import { FC, useEffect } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { PropagateLoader } from "react-spinners";
import { FormattedMessage } from "react-intl";
import { isCancelledErrorProcessor } from "../../utils/AppUtils";
import { getUrlFromShortCode } from "../../config/axios/apis/miscellaneous/miscellaneous";

interface ShortUrlHandlerProps {}

const VALID_CODE_REGEX = /^[a-z0-9]{3}-[a-z0-9]{3}$/; // TODO: Probably only lowercase letters are allowed, check it when we have a working API.

const ShortUrlHandler: FC<ShortUrlHandlerProps> = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { code } = useParams();

    useEffect(() => {
        if (!code || !code.match(VALID_CODE_REGEX)) {
            return undefined;
        }

        const controller = new AbortController();

        getUrlFromShortCode(true, { signal: controller.signal, params: { hash: code } })
            .then((res) => {
                if (res.data) {
                    // URL is expected to contain https
                    const url = new URL(res.data);
                    window.location.assign(url);
                    // // dirtyness to handle malformed redirect urls
                    // if (res.data.includes("http")) {
                    //     window.location.assign(`${res.data}`);
                    // } else {
                    //     window.location.assign(`//${res.data}`);
                    // }
                }
            })
            .catch(isCancelledErrorProcessor)
            .catch(() => navigate("/not-valid"));

        return () => controller.abort();
    }, [code]);

    if (!code || !code.match(VALID_CODE_REGEX)) {
        return <Navigate to="/not-valid" />;
    }

    return (
        <Container maxWidth={false} sx={{ padding: "0 !important", height: "100%" }}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    gap: "20px",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <PropagateLoader cssOverride={{ height: "16px", right: "8px" }} color={theme.palette.primary.main} />
                </Box>
                <Box>
                    <FormattedMessage id="short-url-handler.label.verifying" defaultMessage="Verifying..." />
                </Box>
            </Box>
        </Container>
    );
};

export default ShortUrlHandler;
