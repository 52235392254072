import { Typography } from "@mui/material";
import { CSSProperties, useMemo } from "react";

interface IProps {
    heading?: JSX.Element | string | number;
    subheading?: JSX.Element | string | number;
    withWrapper?: boolean;
    wrapperStyles?: CSSProperties;
}

const HeaderSubheaderTemplate = ({ heading, subheading, withWrapper = true, wrapperStyles }: IProps) => {
    const renderData = useMemo(
        () => (
            <>
                {heading !== undefined && (
                    <Typography variant="h6" align="left" fontWeight={900} fontSize="15px">
                        {heading}
                    </Typography>
                )}
                {subheading !== undefined && (
                    <Typography variant="body2" align="left" sx={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}>
                        {subheading}
                    </Typography>
                )}
            </>
        ),
        [heading, subheading],
    );
    return withWrapper ? <div style={wrapperStyles}>{renderData}</div> : renderData;
};

export default HeaderSubheaderTemplate;
