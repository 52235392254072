import * as yup from "yup";
import { OrganizationForm } from "../../types";
import { isValidLength } from "../../../../../validators/validators";
import { VALID_EMAIL_REGEX } from "../../../../../validators/validatorConstants";

export const defaultValues: OrganizationForm = {
    name: "",
    address: "",
    firstName: "",
    lastName: "",
    email: "",
    permission: false,
    recaptchaToken: "",
};
export const schema: yup.ObjectSchema<OrganizationForm> = yup.object().shape({
    name: yup
        .string()
        .ensure()
        .required("Organization name cannot be empty")
        .test("maxLength", "Organization name should be between 1 and 128 characters long", (value) => {
            if (value) {
                return !isValidLength(value, 1, 128);
            }
            return true;
        }),
    address: yup
        .string()
        .ensure()
        .required("Address cannot be empty")
        .test("length", "Address should be between 1 and 128 characters long", (value) => {
            if (value) {
                return !isValidLength(value, 1, 128);
            }
            return true;
        }),
    firstName: yup.string().ensure().required("First Name is a required field"),
    lastName: yup.string().ensure().required("Last Name is a required field"),
    email: yup.string().ensure().required("Email is a required field").matches(VALID_EMAIL_REGEX, {
        excludeEmptyString: true,
        message: "Please input a valid email",
    }),
    permission: yup.bool().default(false).oneOf([true]),
    recaptchaToken: yup.string().ensure(),
});
