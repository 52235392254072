import { FC } from "react";
import { FormattedMessage } from "react-intl";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { StyledBrandingWrapper } from "../../../../components/Branding/BrandingStyles";
import { StyledBrandingInfo, StyledBrandingMessage, StyledSetupLabel, StyledSetupLabelInline, StyledWelcomeLabel } from "../styles/styles";

interface BrandingProps {
    // product: string;
    hideSetupLabel: boolean;
}
const Branding: FC<BrandingProps> = ({ hideSetupLabel }) => {
    return (
        <StyledBrandingWrapper>
            <StyledWelcomeLabel variant="h2">
                <FormattedMessage id="non-logged.create-organization-welcome" defaultMessage=" Welcome," />
            </StyledWelcomeLabel>
            <StyledBrandingMessage variant="subtitle1">
                <FormattedMessage
                    id="non-logged.create-organization-branding-message"
                    defaultMessage="You’re just one step away from joining the 1health network"
                />
            </StyledBrandingMessage>
            <StyledBrandingInfo>
                <FormattedMessage
                    id="non-logged.create-organization-info-message"
                    defaultMessage="Let’s get some information about how you want your {br} organization to appear inside the platform."
                    values={{ br: <br /> }}
                />
            </StyledBrandingInfo>
            {!hideSetupLabel && (
                <StyledSetupLabel variant="h3" fontWeight={600}>
                    <div>
                        <FormattedMessage
                            id="non-logged.create-organization-info-guide"
                            defaultMessage="{span} your organization to begin"
                            values={{
                                span: (
                                    <StyledSetupLabelInline>
                                        <FormattedMessage id="non-logged.create-organization-info-guide-span" defaultMessage="Setup" />
                                    </StyledSetupLabelInline>
                                ),
                            }}
                        />
                    </div>
                    <ArrowRightAltIcon />
                </StyledSetupLabel>
            )}
        </StyledBrandingWrapper>
    );
};
export default Branding;
