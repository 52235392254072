import { BUILD_NUMBER, NODE_ENV, SESSION_STORAGE_VERSION_KEY } from "../../config/app.config";

const ERROR_RETRY_TIMEOUT_MULTIPLIER = 1500;
const ERROR_RETRY_MAX_COUNT = 5;
const ERROR_MISSING_VERSION_FILE_MESSAGE = "missing version file";
const NO_SCRIPT_TAG = "<noscript>You need to enable JavaScript to run this app.</noscript>";

let timer: NodeJS.Timeout | undefined;
let retryTimer: NodeJS.Timeout | undefined;
let errorCounter = 0;

function getVersionTextFile() {
    return fetch(`/version.txt?v=${Date.now()}`).then((res) => res.text());
}

function addTimer() {
    timer = setTimeout(() => {
        clearTimeout(timer);
        timer = undefined;
    }, 30000);
}

function checkVersion() {
    return getVersionTextFile()
        .then((versionString) => {
            if (BUILD_NUMBER?.trim() === versionString.trim()) {
                return;
            }

            if (versionString.trim().includes(NO_SCRIPT_TAG)) {
                throw new Error(ERROR_MISSING_VERSION_FILE_MESSAGE);
            }

            if (timer) {
                clearTimeout(timer);
            }

            if (retryTimer) {
                clearTimeout(retryTimer);
                retryTimer = undefined;
            }

            errorCounter = 0;

            sessionStorage.setItem(SESSION_STORAGE_VERSION_KEY, "1");
            window.document.location.reload();
        })
        .catch((error) => {
            errorCounter += 1;
            if (retryTimer) {
                clearTimeout(retryTimer);
                retryTimer = undefined;
            }

            if (errorCounter === ERROR_RETRY_MAX_COUNT) {
                throw error;
            }

            retryTimer = setTimeout(checkVersion, errorCounter * ERROR_RETRY_TIMEOUT_MULTIPLIER);
        });
}

export default function versionSyncListener() {
    if (NODE_ENV === "development") {
        return;
    }

    if (retryTimer) {
        clearTimeout(retryTimer);
        retryTimer = undefined;
    }

    errorCounter = 0;

    if (timer) {
        return;
    }

    addTimer();
    checkVersion();
}
