import { FC, PropsWithChildren, createContext, useMemo } from "react";
import GridConfigManager from "./GridConfigCacheManager/GridConfigCacheManager";
import { defaultCache } from "./utils";

export const GenericGridConfigurationContext = createContext<{ configManager: GridConfigManager | undefined }>({ configManager: undefined });

const GenericGridConfigurationManager: FC<PropsWithChildren> = ({ children }) => {
    const providerValue = useMemo(
        () => ({
            configManager: new GridConfigManager(defaultCache),
        }),
        [],
    );

    return <GenericGridConfigurationContext.Provider value={providerValue}>{children}</GenericGridConfigurationContext.Provider>;
};

export default GenericGridConfigurationManager;
