import { Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { JSONStringObject } from "../../../utils/appInterfaces/AppInterfaces";

interface IProps {
    dto: JSONStringObject;
}

const OtherProductCodesTemplate = (props: IProps) => {
    const { dto } = props;
    return (
        <div>
            <Typography variant="h6" align="left" fontWeight={900} fontSize="15px">
                <FormattedMessage id="label.otherProductsCode" defaultMessage="Other Product Codes" />
            </Typography>
            {Object.entries(dto).length ? (
                Object.entries(dto).map(([k, v]) => (
                    <Typography variant="body2" align="left" key={`${k}:${v}`}>
                        <>
                            {k}: {v}
                        </>
                    </Typography>
                ))
            ) : (
                <Typography variant="body2" align="left">
                    -
                </Typography>
            )}
        </div>
    );
};

export default OtherProductCodesTemplate;
