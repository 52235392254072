import { FC } from "react";
import { PuffLoader } from "react-spinners";
import { useTheme } from "@mui/material";
import { useTypedSelector } from "../../../store";
import "./actionSpinner.css";

const ActionSpinner: FC = () => {
    const theme: any = useTheme();
    const show = useTypedSelector((state) => state.spinnerReducer.showSpinner);

    return (
        <div className={show ? "showActionSpinner" : "hideActionSpinner"} key="spinner">
            <PuffLoader color={theme.palette.primary.main} loading={show} size={150} />
        </div>
    );
};

export default ActionSpinner;
