import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export const defaultPageOverlayColor = "#fff";

export interface PageOverlayReducerState {
    pageOverlay: boolean;
    pageOverlayMessages: string[];
    pageOverlayColor: string;
}

const initialState: PageOverlayReducerState = {
    pageOverlay: false,
    pageOverlayMessages: [],
    pageOverlayColor: defaultPageOverlayColor,
};

const pageOverLaySlice = createSlice({
    name: "pageOverLay",
    initialState,
    reducers: {
        setPageOverlay: (state, action: PayloadAction<Partial<PageOverlayReducerState>>) => {
            return { ...state, ...action.payload };
        },
        setPageOverlayMessages: (state, action: PayloadAction<string[]>) => {
            state.pageOverlayMessages = action.payload;
        },
        addPageOverlyMessages: (state, action: PayloadAction<string>) => {
            state.pageOverlayMessages = [...state.pageOverlayMessages, action.payload]; // It might later needed.
        },
        setPageOverlyColor: (state, action: PayloadAction<string>) => {
            state.pageOverlayColor = action.payload; // It might later needed.
        },
        resetPageOverlayState: (state) => {
            state.pageOverlay = false;
            state.pageOverlayMessages = [];
            state.pageOverlayColor = defaultPageOverlayColor;
        },
    },
});

export const { setPageOverlay, setPageOverlayMessages, resetPageOverlayState } = pageOverLaySlice.actions;
export default pageOverLaySlice.reducer;
