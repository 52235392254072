import { FunctionComponent, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setSimpleSpinner } from "../../../reducers/spinnerReducer";

interface SuspenseSpinnerProps {}

const SuspenseSpinner: FunctionComponent<SuspenseSpinnerProps> = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setSimpleSpinner(true));

        return () => {
            dispatch(setSimpleSpinner(false));
        };
    }, []);

    return null;
};

export default SuspenseSpinner;
