import { FC, ReactNode, useLayoutEffect } from "react";
import { SvgIcon } from "@mui/material";
import CopyrightIcon from "@mui/icons-material/Copyright";
import { ReactComponent as PoweredByOneHealth } from "../../assets/1health-logo-powered-by-gray.svg";

import { classes, StyledBox } from "./GenericPublicPageWrapperStyles";

interface GenericPublicPageWrapperProps {
    left: ReactNode;
    right: ReactNode;
    isSubdomain?: boolean;
}
const GenericPublicPageWrapper: FC<GenericPublicPageWrapperProps> = ({ left, right, isSubdomain }) => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <StyledBox className={classes.pageWrapper} isSubdomain={isSubdomain}>
            <div className={classes.leftSidePageWrapper}>{left}</div>
            <div className={classes.rightSidePageWrapper}>{right}</div>
            {isSubdomain && (
                <div style={{ width: "100%", height: "5vh", display: "flex", alignItems: "center", justifyContent: "flex-end", backgroundColor: "white" }}>
                    <SvgIcon component={PoweredByOneHealth} inheritViewBox sx={{ width: "175px" }} />
                    <CopyrightIcon sx={{ fontSize: "1.2em", fontVariantPosition: "super", marginLeft: "3px" }} />
                </div>
            )}
        </StyledBox>
    );
};

export default GenericPublicPageWrapper;
