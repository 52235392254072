import { createSlice } from "@reduxjs/toolkit";

export interface ProductsUiState {
    openProductModal: boolean;
}

const initialState: ProductsUiState = {
    openProductModal: false,
};

export const productsUiSlice = createSlice({
    name: "productsUi",
    initialState,
    reducers: {
        openAddProductModal: (state) => {
            state.openProductModal = true;
        },
        closeAddProductModal: (state) => {
            state.openProductModal = false;
        },
    },
});

export const { openAddProductModal, closeAddProductModal } = productsUiSlice.actions;

export default productsUiSlice.reducer;
