import { FC } from "react";
import { createBrowserRouter, createRoutesFromElements, Navigate, Outlet, Route, RouterProvider } from "react-router-dom";
import versionSyncListener from "../../route/versionSyncService/versionSyncService";
import SuspenseSpinner from "../../components/Common/SuspenseSpinner/SuspenseSpinner";
import ShortUrlHandler from "./ShortUrlHandler";
import InvalidShortUrl from "./InvalidShortUrl";

interface ShortUrlRouterProps {}

const ShortUrlRouter: FC<ShortUrlRouterProps> = () => {
    const routes = createBrowserRouter(
        createRoutesFromElements(
            <Route path="" element={<Outlet />}>
                <Route index path="/:code?" element={<ShortUrlHandler />} />
                <Route path="/not-valid" element={<InvalidShortUrl />} />
                <Route path="/*" element={<Navigate to="/not-valid" />} />
            </Route>,
        ),
    );

    routes.subscribe(versionSyncListener);

    return <RouterProvider fallbackElement={<SuspenseSpinner />} router={routes} future={{ v7_startTransition: true }} />;
};

export default ShortUrlRouter;
