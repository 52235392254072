import { useState, useEffect, ReactNode } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { usePlacesWidget } from "react-google-autocomplete";
import { useSelector } from "react-redux";
import { cloneDeep } from "lodash";
import { Control, Controller, FieldErrors, Path } from "react-hook-form";
import { AppAddressCountries } from "../../../../../utils/appEnums/AppEnums";
import { RootState } from "../../../../../store";
import { defaultPlaceAddress, GoogleAddressComponent, Place, PlaceAddress } from "../../../../../utils/appInterfaces/AppInterfaces";
import { StyledTextField, classes } from "./GoogleAutocompleteAddressStyles";
import { Address } from "./GoogleAutocompleteAddressTypes";
import { TooltipWrapper } from "../../../../TooltipWrapper/TooltipWrapper";
import { useHideGoogleAddressOptionsOnScroll } from "../../../../../Hooks/useHideGoogleAddressOptionOnScroll";
import { fieldVariant } from "../../../../../utils/AppUtils";

interface IProps<T extends Address> {
    country: string | null;
    control: Control<T>;
    errors: FieldErrors<T>;
    getValues: (payload?: string | string[]) => Object;
    setAddressValuesFromPlacesApi: (placeAddress: PlaceAddress, index?: number) => void;
    onAddressChange: () => void;
    screenName: string;
    index?: number;
    fieldLabel?: string;
    required?: boolean;
    tooltipText?: string | ReactNode;
}

const GoogleAutocompleteAddress = <T extends Address>(props: IProps<T>): JSX.Element => {
    const { country, control, errors, getValues, setAddressValuesFromPlacesApi, onAddressChange, screenName, index, fieldLabel, required, tooltipText } = props;
    const [place, setPlace] = useState<Place | null>(null);
    const [countryCode, setCountryCode] = useState<string | null>("us");
    const googlePlacesApiKey: string = useSelector((store: RootState) => store.uiSliceReducer.googlePlacesApiKey);
    const { formatMessage } = useIntl();

    const { ref: placeRef }: any = usePlacesWidget({
        language: "en",
        apiKey: googlePlacesApiKey,
        onPlaceSelected: (newPlace) => {
            setPlace(newPlace);
        },
        options: {
            types: ["address"],
            componentRestrictions: { country: countryCode },
        },
    });
    const handleFocus = useHideGoogleAddressOptionsOnScroll(placeRef);

    const handlePlacesApiResponse = (response: Place): void => {
        if (response?.address_components) {
            const placeAddress = cloneDeep(defaultPlaceAddress);
            placeAddress.lat = response.geometry.location.lat();
            placeAddress.lon = response.geometry.location.lng();
            placeAddress.formatted_address = response.formatted_address;
            response.address_components.forEach((item: GoogleAddressComponent) => {
                if (item.types.includes("administrative_area_level_1")) {
                    placeAddress.administrative_area_level_1 = item;
                }
                if (item.types.includes("administrative_area_level_2")) {
                    placeAddress.administrative_area_level_2 = item;
                }
                if (item.types.includes("locality")) {
                    placeAddress.locality = item;
                }
                if (item.types.includes("postal_code")) {
                    placeAddress.postal_code = item;
                }
                if (item.types.includes("country")) {
                    placeAddress.country = item;
                }
                if (item.types.includes("route")) {
                    placeAddress.route = item;
                }
                if (item.types.includes("street_number")) {
                    placeAddress.street_number = item;
                }
            });
            if (index !== undefined) {
                setAddressValuesFromPlacesApi(placeAddress, index);
            } else {
                setAddressValuesFromPlacesApi(placeAddress);
            }
        }
    };

    useEffect(() => {
        if (place) {
            handlePlacesApiResponse(place);
        }
    }, [place]);

    useEffect(() => {
        switch (country) {
            case AppAddressCountries.CANADA:
                setCountryCode("ca");
                break;
            case AppAddressCountries.UNITED_STATES_OF_AMERICA:
                setCountryCode("us");
                break;
            case AppAddressCountries.AUSTRALIA:
                setCountryCode("au");
                break;
            default:
                setCountryCode("us");
        }
    }, [country]);

    const showClasses = (): string => {
        return getValues("address") !== "" ? classes.filledRoot : classes.initialRoot;
    };

    return (
        <Controller
            name={"address" as Path<T>}
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
                <TooltipWrapper
                    title={
                        tooltipText ?? (
                            <FormattedMessage
                                id="google-autocomplete-address-tooltip-text"
                                defaultMessage="If you are looking for an Apartment number or Suite, continue typing your address past the street number & name - do not click the location and try to add additional text afterward."
                            />
                        )
                    }
                >
                    <StyledTextField
                        id="google-auto-compelete"
                        inputRef={placeRef}
                        variant={fieldVariant}
                        margin="normal"
                        color="primary"
                        required={!!required}
                        fullWidth
                        placeholder={
                            fieldLabel ??
                            formatMessage({
                                id: "profile.contact-points.placeholder.address-address-line1",
                                defaultMessage: "Search and select locations from google",
                            })
                        }
                        label={fieldLabel ?? <FormattedMessage id="profile.contact-points.placeholder.label-address-line1" defaultMessage="Address Line 1" />}
                        value={value}
                        onChange={(e) => {
                            onAddressChange();
                            onChange(e);
                        }}
                        onFocus={handleFocus}
                        onBlur={() => onBlur()}
                        error={!!errors.address}
                        helperText={
                            errors.address ? (
                                <FormattedMessage id={`${screenName}.address-${errors.address.type}`} defaultMessage={`${errors?.address?.message}`} />
                            ) : (
                                " "
                            )
                        }
                        InputLabelProps={{ shrink: document.activeElement === placeRef || value !== "" }}
                        classes={{ root: showClasses() }}
                    />
                </TooltipWrapper>
            )}
        />
    );
};

export default GoogleAutocompleteAddress;
