export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const OAUTH_BASE_URL = process.env.REACT_APP_OAUTH_BASE_URL;
export const REALM_NAME = process.env.REACT_APP_REALM_NAME;
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// export const NOTIFICATION_URL = process.env.REACT_APP_NOTIFICATION_URL;
export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
export const ENV_PREFIX = process.env.REACT_APP_ENV_PREFIX;
export const { NODE_ENV } = process.env;
export const BUILD_NUMBER = process.env.REACT_APP_BITBUCKET_BUILD_NUMBER;
export const BUILD_DATE = process.env.REACT_APP_BUILD_DATE;
export const BUILD_VERSION = `1health FrontEnd Build-${process.env.REACT_APP_BITBUCKET_BUILD_NUMBER} ${process.env.REACT_APP_BITBUCKET_COMMIT}`;
export const SENTRY_DSN_KEY = process.env.REACT_APP_SENTRY_DSN_KEY;
export const SENTRY_SAMPLE_RATE = process.env.REACT_APP_SENTRY_SAMPLE_RATE;
export const GOOGLE_MEASUREMENT_ID = process.env.REACT_APP_GOOGLE_MEASUREMENT_ID;

export const AM_CHARTS_5_LICENSE_KEY = process.env.REACT_APP_AM_CHARTS_5_LICENSE_KEY;

export const LOCAL_STORAGE_ITEM_LOCALE = "Locale";
export const LOCAL_STORAGE_ITEM_LANG = "Language";
export const APP_DEFAULT_LANGUAGE = "en";
export const DEFAULT_TENANT_ID = 1;
export const MY_TESTS_CUSTOM_LOGIN_COOKIE_NAME = "my-tests-custom-login";
export const CODE_VERIFIER_LIFE_SECONDS = 300;
export const TENANT_1HEALTH_NAME = "1health";

export const SESSION_STORAGE_VERSION_KEY = "new-data-loaded";
