import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useSessionStorage } from "../../Hooks/useSessionStorage";
import { success } from "../../utils/AppUtils";
import { SESSION_STORAGE_VERSION_KEY } from "../../config/app.config";

export default function useVersionSyncNotification() {
    const { setStorageValue, value } = useSessionStorage<number>(SESSION_STORAGE_VERSION_KEY, 0);

    useEffect(() => {
        let timer: NodeJS.Timeout;

        const observer = new MutationObserver((_mutations, observerInstance) => {
            if (timer) {
                clearTimeout(timer);
            }

            if (value === 1) {
                timer = setTimeout(() => {
                    setStorageValue(0);
                    success(<FormattedMessage id="notification.loaded-new-version" defaultMessage="Congratulations, now you are using the latest version." />);
                }, 2500);
            }

            observerInstance.takeRecords();
            observerInstance.disconnect();
        });

        observer.observe(document, { subtree: true, childList: true });

        return () => {
            observer.takeRecords();
            observer.disconnect();
        };
    }, [value]);
}
