import { Card, styled } from "@mui/material";

const identifier = "CollapsibleTabRotatingIcon";

export const classes = { border: `${identifier}-border` };

export const StyledCard = styled(Card)(() => ({
    width: "100%",
    marginBottom: 0,
    height: "100%",
    boxSizing: "border-box",
    borderRadius: 0,
    boxShadow: "unset",
    "& *": { boxSizing: "border-box" },
    [`& .${classes.border}`]: { border: "1px solid #cccccc", borderLeft: 0, borderRight: 0, borderBottom: 0, boxShadow: "unset" },
}));
