import { styled } from "@mui/material";

const identifier = "Branding";

export const classes = {
    brandingWrapper: `${identifier}-brandingWrapper`,
    brandingLogoName: `${identifier}-brandingLogoName`,
    brandingTagline: `${identifier}-brandingTagline`,
    brandingSubTitle: `${identifier}-brandingSubTitle`,
    oneHealthLogo: `${identifier}-oneHealthLogo`,
    brandLogoText: `${identifier}-brandLogoText`,
    brandingDescription: `${identifier}-brandingDescription`,
    brandingBottomWrapper: `${identifier}-brandingBottomWrapper`,
    reviewStatusWrapper: `${identifier}-reviewStatusWrapper`,
    yearInReview: `${identifier}-yearInReview`,
    yearInReviewTopBorder: `${identifier}-yearInReviewTopBorder`,
    reviewStatusNumber: `${identifier}-reviewStatusNumber`,
    reviewStatusDescription: `${identifier}-reviewStatusDescription`,
    brandingWelcomeMessage: `${identifier}-brandingWelcomeMessage`,
    brandingMessage: `${identifier}-brandingMessage`,

    bottomMessage: `${identifier}-bottomMessage`,
    bottomMessageTopBorder: `${identifier}-bottomMessageTopBorder`,
};

export const StyledBrandingWrapper = styled("div")(() => ({
    position: "relative",
    margin: "0 3rem",
    "@media(max-width:959px)": { padding: "0 2rem", paddingTop: "2em", margin: "0" },
    [`& .${classes.brandingLogoName}`]: {
        fontSize: "4rem",
        lineHeight: 1.7,
        fontFamily: "Inter",
        fontWeight: 600,
        color: "#ffffff",
        margin: "1rem 0rem",
        "@media(min-width:1200px)": { fontSize: "4rem" },
        "@media(max-width:959px)": { textAlign: "center", fontSize: "3.3rem" },
        "@media(max-width:768px)": { fontSize: "3.2rem" },
    },
    [`& .${classes.brandingTagline}`]: {
        fontSize: "1.2rem",
        lineHeight: 1.5,
        fontFamily: "Inter",
        color: "#ffffff",
        fontWeight: "300",
        margin: "1rem 0rem",
        maxWidth: "calc(100% - 18px)",
        "@media(min-width:1200px)": { fontSize: "1.2rem" },
        "@media(max-width:959px)": { textAlign: "center", fontSize: "1rem" },
        "@media(max-width:768px)": { fontSize: ".9rem" },
    },
    [`& .${classes.brandingSubTitle}`]: {
        fontSize: "1.4rem",
        lineHeight: 1.2,
        fontFamily: "Inter",
        fontWeight: 100,
        color: "#ffffff",
        margin: "1rem 0rem",
        "@media(min-width:1200px)": { fontSize: "1.75rem" },
        "@media(max-width:959px)": { textAlign: "center", fontSize: "1.3rem" },
        "@media(max-width:768px)": { fontSize: "1.2rem" },
    },
    [`& .${classes.oneHealthLogo}`]: {
        height: "auto",
        aspectRatio: "attr(width) / attr(height)",
        "@media(min-width:1200px)": { width: "350px" },
        "@media (min-width:960px) and (max-width:1200px)": { width: "250px" },
        "@media(max-width:959px)": { width: "180px", display: "block", margin: "0 auto" },
        "@media(max-width:768px)": { width: "160px" },
        "@media(max-width:600px)": { width: "140px" },
    },
    [`& .${classes.brandLogoText}`]: {
        fontSize: "4rem",
        lineHeight: 1.21,
        color: "#fff",
        margin: 0,
        fontFamily: "Ubuntu",
        "@media(min-width:1201px)": { fontSize: "5.8rem" },
        "@media(max-width:959px)": { textAlign: "center", fontSize: "3rem" },
        "@media(max-width:768px)": { textAlign: "center", fontSize: "2.8rem" },
    },
    [`& .${classes.brandingDescription}`]: {
        fontSize: ".96rem",
        lineHeight: 1.21,
        color: "#fff",
        margin: 0,
        fontWeight: 500,
        "@media(min-width:1201px)": { fontSize: "1.16rem" },
        "@media(max-width:959px)": { textAlign: "center", fontSize: ".9rem" },
        "@media(max-width:768px)": { textAlign: "center", fontSize: ".85rem" },
    },
    [`& .${classes.brandingBottomWrapper}`]: {
        position: "absolute",
        bottom: "-100%",
        width: "100%",
        "@media(max-width:959px)": { position: "relative", bottom: "0% !important", paddingTop: "1rem", textAlign: "center" },
        "@media(max-width:320px)": { display: "none" },
    },
    [`& .${classes.reviewStatusWrapper}`]: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "@media(max-width:959px)": { width: "60%", margin: "0 auto" },
        "@media(max-width:768px)": { width: "80%" },
        "@media(max-width:576px)": { width: "95%" },
    },
    [`& .${classes.yearInReview}`]: {
        fontFamily: "Inter",
        fontSize: ".96rem",
        lineHeight: 1.21,
        color: "#fff",
        fontWeight: 600,
        "@media(min-width:1201px)": { fontSize: "1rem", fontWeight: 700 },
    },
    [`& .${classes.yearInReviewTopBorder}`]: { borderTop: "4px solid #FBC84A", paddingTop: ".2rem" },
    [`& .${classes.reviewStatusNumber}`]: {
        margin: 0,
        color: "#fff",
        fontSize: "1.8rem",
        fontWeight: 700,
        "@media(min-width:1201px)": { fontSize: "2rem", fontWeight: 900 },
        "@media(max-width:960px)": { fontSize: "1.5rem" },
        "@media(max-width:768px)": { fontSize: "1.3rem" },
    },
    [`& .${classes.reviewStatusDescription}`]: {
        margin: 0,
        color: "#fff",
        fontWeight: 500,
        fontSize: ".96rem",
        "@media(min-width:1201px)": { fontSize: "1.1rem" },
        "@media(max-width:960px)": { fontSize: ".9rem" },
        "@media(max-width:768px)": { fontSize: ".85rem" },
    },
    [`& .${classes.brandingWelcomeMessage}`]: {
        fontSize: "4rem",
        lineHeight: 1.7,
        fontFamily: "Inter",
        fontWeight: 600,
        color: "#ffffff",
        margin: "1rem 0rem",
        "@media(min-width:1200px)": {
            fontSize: "4rem",
        },
        "@media(max-width:959px)": {
            textAlign: "center",
            fontSize: "3.3rem",
        },
        "@media(max-width:768px)": {
            fontSize: "3.2rem",
        },
    },
    [`& .${classes.brandingMessage}`]: {
        fontSize: "1.2rem",
        lineHeight: 1.5,
        fontFamily: "Inter",
        color: "#ffffff",
        fontWeight: "300",
        margin: "1rem 0rem",
        "@media(min-width:1200px)": {
            fontSize: "1.2rem",
        },
        "@media(max-width:959px)": {
            textAlign: "center",
            fontSize: "1rem",
        },
        "@media(max-width:768px)": {
            fontSize: ".9rem",
        },
    },
    [`& .${classes.bottomMessage}`]: {
        fontFamily: "Inter",
        fontSize: ".96rem",
        lineHeight: 1.21,
        color: "#fff",
        fontWeight: 700,
        "@media(min-width:1201px)": {
            fontSize: "1rem",
            fontWeight: 700,
        },
    },
    [`& .${classes.bottomMessageTopBorder}`]: {
        borderTop: "3px solid #FBC84A",
        paddingTop: ".3rem",
        width: "2rem",
        fontSize: ".96rem",
        "@media(max-width:959px)": {
            margin: "auto",
        },
    },
}));
