import { createSlice } from "@reduxjs/toolkit";

export interface ProductGroupsUiState {
    openProductGroupAddModal: boolean;
    openProductGroupEditModal: boolean;
}

const initialState: ProductGroupsUiState = {
    openProductGroupAddModal: false,
    openProductGroupEditModal: false,
};

const productGroupsUiReducer = createSlice({
    initialState,
    name: "productGroupsUiReducer",
    reducers: {
        openProductGroupModal(state: ProductGroupsUiState) {
            return { ...state, openProductGroupAddModal: true };
        },
        closeProductGroupModal(state: ProductGroupsUiState) {
            return { ...state, openProductGroupAddModal: false };
        },
        openProductGroupEditModal(state: ProductGroupsUiState) {
            return { ...state, openProductGroupEditModal: true };
        },
        closeProductGroupEditModal(state: ProductGroupsUiState) {
            return { ...state, openProductGroupEditModal: false };
        },
    },
});

export const { openProductGroupModal, closeProductGroupModal, openProductGroupEditModal, closeProductGroupEditModal } = productGroupsUiReducer.actions;

export default productGroupsUiReducer.reducer;
