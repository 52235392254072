import { AxiosRequestConfig, AxiosResponse } from "axios";
import AxiosActions from "../../AxiosActions";
import { getAxiosInstances } from "../../../../utils/AppUtils";

export const downloadInsuranceFileHealthcare = (
    insuranceId,
    fileId,
    patientId,
    config: AxiosRequestConfig,
    spinner: boolean = false,
): Promise<AxiosResponse> => {
    return AxiosActions.get(
        getAxiosInstances().V2,
        `/health/insurance/${insuranceId}/patient/${patientId}/file/${fileId}/download`,
        { ...config, responseType: "blob" },
        spinner,
    );
};

export const downloadInsuranceFileLoggedUser = (insuranceId, fileId, config: AxiosRequestConfig, spinner: boolean = false): Promise<AxiosResponse> => {
    return AxiosActions.get(getAxiosInstances().V2, `/health/insurance/${insuranceId}/file/${fileId}/download`, { ...config, responseType: "blob" }, spinner);
};

export const getPreviewFile = (id: number, fromSKid: number | undefined, spinner: boolean = true, config = {}): Promise<AxiosResponse> => {
    return AxiosActions.get(
        getAxiosInstances().V2,
        `/file/${id}/download?preview=true${fromSKid ? `&fromSKid=${fromSKid}` : ""}`,
        { ...config, responseType: "blob", timeout: 15000 },
        spinner,
        true,
    );
};
export const getInsuranceOrganizations = (page: number, size: number, name?: string | undefined, config = {}): Promise<AxiosResponse> => {
    return AxiosActions.get(
        getAxiosInstances().V2,
        `health/insurance/organizations?page=${page}&size=${size}${typeof name !== "undefined" ? `&name=${name}` : ""}`,
        { withCredentials: true, ...config },
        false,
    );
};
export const getInsurancesForPatient = (config = {}) => {
    return AxiosActions.get(getAxiosInstances().V2, `health/patient/insurance?page=0&size=100`, { ...config, withCredentials: true }, true, false);
};
export const createInsurance = (dto: any, showSpinner: boolean): Promise<AxiosResponse> => {
    return AxiosActions.post(getAxiosInstances().V2, `health/patient/insurance`, dto, {}, showSpinner);
};

export const updateInsurance = (insuranceId: number, dto: any, showSpinner: boolean): Promise<AxiosResponse> => {
    return AxiosActions.put(getAxiosInstances().V2, `health/patient/insurance/${insuranceId}`, dto, {}, showSpinner);
};

export const deleteInsurance = (insuranceId: number): Promise<AxiosResponse> => {
    return AxiosActions.delete(getAxiosInstances().V2, `health/insurance/${insuranceId}`, {}, true);
};
export const getPatientsInsuranceByPatientId = (patientId: number, config: any): Promise<AxiosResponse> => {
    return AxiosActions.get(getAxiosInstances().V2, `organization/patient/${patientId}/insurance/list`, config, true);
};

export const updatePatientInsuranceByPatientId = (patientId: number, insuranceId: number, dto: any, showSpinner: boolean = true): Promise<AxiosResponse> => {
    return AxiosActions.put(getAxiosInstances().V2, `organization/patient/${patientId}/insurance/${insuranceId}`, dto, {}, showSpinner);
};

export const createPatientInsuranceByPatientId = (patientId: number, dto: any, showSpinner: boolean = true): Promise<AxiosResponse> => {
    return AxiosActions.post(getAxiosInstances().V2, `organization/patient/${patientId}/insurance`, dto, {}, showSpinner);
};

export const deleteInsuranceByPatientId = (insuranceId: number, patientId: number): Promise<AxiosResponse> => {
    return AxiosActions.delete(getAxiosInstances().V2, `organization/patient/${patientId}/insurance/${insuranceId}`, {}, true);
};
