import { Card, CardContent, Container, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { cardWrapper } from "../../../../AppGlobalStyles";
import CollapsibleTabRotatingIcon from "../../../../components/Common/CollapsibleTabRotatingIcon/CollapsibleTabRotatingIcon";
import ProductDetailsHeader from "../../../../components/Common/ProductDetailsHeader/ProductDetailsHeader";
import HeaderSubheaderTemplate from "../../../../components/Common/TemplatingHelpers/HeaderSubheaderTemplate";
import OtherProductCodesTemplate from "../../../../components/Common/TemplatingHelpers/OtherProductCodesTemplate";
import TestOfferedDetails from "../../../../components/Common/TestOfferedDetails/TestOfferedDetails";
import { TestProductTemplatingObject } from "../../../../utils/appInterfaces/AppInterfaces";
import TestProductsIframe from "./TestProductsIframe/TestProductsIframe";
import { all } from "../../../../config/axios/Axios";
import { BO_CORE_DEFAULT_VALUE, info } from "../../../../utils/AppUtils";
import { getProductDataById } from "../../../../config/axios/apis/test-product/test-product";
import { getLTCTestOfferedById } from "../../../../config/axios/apis/test-offered/test-offered";
import { getSupplyProductById } from "../../../../config/axios/apis/supply-product/supply-product";
import { getPreviewFile } from "../../../../config/axios/apis/insurance/insurance.";
import { getAllFilesByBoi } from "../../../../config/axios/apis/bocore/bocore";
import { getTestResultDefinitions } from "../../../../config/axios/apis/test-result/test-result";
import { PAYMENT_TYPE } from "../../../../utils/appEnums/AppEnums";

const PREFIX = "TestProductDetails";

const classes = {
    divGridStyle: `${PREFIX}-divGridStyle`,
};

const StyledGrid = styled(Grid)(() => ({
    [`& .${classes.divGridStyle}`]: {
        display: "grid",
        gap: "30px",
        gridAutoFlow: "row",
        overflow: "auto",
        gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
        padding: 0,
        "@media(max-width: 599px)": {
            gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
        },
    },
}));

interface IProps {
    testproductId: number;
    addToOrderCallback?: any;
    setOrderList?: any;
    isSelected?: boolean;
    hide?: () => void;
    showAddToOrderBtn?: boolean;
}

const TestProductDetails = ({ testproductId, hide, addToOrderCallback, setOrderList, isSelected, showAddToOrderBtn = true }: IProps) => {
    const [dataObj, setDataObj] = useState<TestProductTemplatingObject>();
    const [loading, setLoading] = useState<boolean>(false);
    const [testProductData, setTestProductData] = useState<any>();

    // const boundOpenExampleReportCB = useMemo(() => openExampleReportCB.bind(null, dataObj.), [])

    useEffect(() => {
        async function getTestProductDetails(testProductId: number) {
            const response = await getProductDataById(testProductId);
            const productData = response.data;
            setTestProductData(productData);
            const requests: Promise<any>[] = [];
            if (productData.labTestOrdered?.id) {
                requests.push(getLTCTestOfferedById(`${productData.labTestOrdered?.id}`, { params: { includeCollectionSpecifications: true } }));
                requests.push(getTestResultDefinitions(productData.labTestOrdered?.id));
            }
            if (productData.supplyProductOrdered?.id) {
                requests.push(getSupplyProductById(`${productData.supplyProductOrdered?.id}`));
            }
            if (requests.length === 0) {
                setDataObj(productData);
                setLoading(false);
                return;
            }
            const res = await all(requests);
            const dataArr = res.map((e) => e.data);

            if (productData?.labTestOrdered?.id || productData?.supplyProductOrdered?.id) {
                const labTestOrderedData = productData.labTestOrdered?.id ? dataArr.find((e) => e.id === productData.labTestOrdered?.id) : null;
                const resultDefinitionsData = dataArr.find((e) => Array.isArray(e.data));
                const supplyProductOrderedData = productData?.supplyProductOrdered?.id
                    ? dataArr.find((e) => e.id === productData?.supplyProductOrdered?.id)
                    : null;
                let collectionDeviceImageUrl: string | undefined;
                if (productData?.supplyProductOrdered) {
                    const supplyProductImagesRes = await getAllFilesByBoi(productData?.supplyProductOrdered?.id, "SupplyProduct", [
                        "SupplyProductHasAttachmentFile",
                    ]);
                    if (supplyProductImagesRes?.data[0]?.fileInstance.id) {
                        const collectionDeviceImageFileRes = await getPreviewFile(supplyProductImagesRes.data[0].fileInstance.id, undefined, false, {});
                        collectionDeviceImageUrl = URL.createObjectURL(collectionDeviceImageFileRes.data);
                    }
                }
                setDataObj({
                    ...productData,
                    labTestOrdered: productData?.labTestOrdered
                        ? {
                              ...productData.labTestOrdered,
                              detailedData: {
                                  ...labTestOrderedData,
                                  resultDefinitions: resultDefinitionsData.data,
                              },
                          }
                        : null,
                    supplyProductOrdered: productData?.supplyProductOrdered
                        ? { ...productData.supplyProductOrdered, detailedData: supplyProductOrderedData, img: collectionDeviceImageUrl }
                        : null,
                });
                setLoading(false);
            }
        }

        if (testproductId) {
            setLoading(true);
            getTestProductDetails(testproductId);
        }
    }, [testproductId]);

    const addToOrderWraper = useCallback(
        (_e) =>
            addToOrderCallback({
                isTestOfferedPanel: dataObj?.labTestOrdered?.detailedData?.isPanel,
                testOffered: dataObj?.labTestOrdered?.detailedData,
                supplyProduct: dataObj?.supplyProductOrdered?.detailedData,
                testProduct: testProductData,
                ...(!dataObj?.labTestOrdered && dataObj?.supplyProductOrdered?.detailedData && { quantity: 1 }),
                ...(dataObj?.labTestOrdered?.detailedData?.isPanel && { selectedPanelTests: dataObj?.labTestOrdered?.detailedData?.testOfferedComponents }),
            }),
        [dataObj],
    );

    const category = useMemo(() => {
        if (dataObj?.labTestOrdered === null) {
            return "Supply Product";
        }
        if (
            dataObj?.labTestOrdered?.detailedData?.therapeuticCategory &&
            dataObj?.labTestOrdered?.detailedData?.therapeuticCategory !== BO_CORE_DEFAULT_VALUE &&
            dataObj?.labTestOrdered?.detailedData?.specialization &&
            dataObj?.labTestOrdered?.detailedData?.specialization !== BO_CORE_DEFAULT_VALUE
        ) {
            if (dataObj?.labTestOrdered.detailedData?.isPanel) {
                return `${dataObj?.labTestOrdered?.detailedData?.therapeuticCategory} · ${dataObj?.labTestOrdered?.detailedData?.specialization} Panel`;
            }

            return `${dataObj?.labTestOrdered?.detailedData?.therapeuticCategory} · ${dataObj?.labTestOrdered?.detailedData?.specialization}`;
        }
        return "";
    }, [
        dataObj?.labTestOrdered,
        dataObj?.labTestOrdered?.detailedData?.isPanel,
        dataObj?.labTestOrdered?.detailedData?.specialization,
        dataObj?.labTestOrdered?.detailedData?.therapeuticCategory,
    ]);

    if (loading) {
        return null;
    }

    const openExampleReportCB = () => {
        if (dataObj?.exampleResultReportFileURL) {
            window.open(dataObj?.exampleResultReportFileURL, "_newtab", "noopener");
        } else {
            info(<FormattedMessage id="test-products-place-order.no-example-report" defaultMessage="There is no example result report" />);
        }
    };

    return (
        <StyledGrid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Card sx={{ ...cardWrapper, marginBottom: 0, height: "100%", boxSizing: "border-box", "& *": { boxSizing: "border-box" } }}>
                {dataObj?.compiledVersionURL ? (
                    <TestProductsIframe
                        url={dataObj.compiledVersionURL}
                        addToOrdercallback={addToOrderWraper}
                        setOrderList={setOrderList}
                        currentProductId={testproductId}
                        backBtnCallback={hide ? (_e) => hide() : undefined}
                        isSelected={isSelected!}
                        category={category}
                        openExampleReportCB={openExampleReportCB}
                        showAddToOrderBtn={showAddToOrderBtn}
                    />
                ) : (
                    <CardContent sx={{ padding: 0, "&:last-child": { paddingBottom: 0 } }}>
                        <ProductDetailsHeader
                            dto={{
                                name: dataObj?.name,
                                imageUrl: dataObj?.primaryImageURL,
                                category,
                                description: dataObj?.description,
                                paymentMethods: dataObj?.allowInsurancePayment ? [PAYMENT_TYPE.CASH, PAYMENT_TYPE.INSURANCE] : [PAYMENT_TYPE.CASH],
                            }}
                            addToOrdercallback={addToOrderWraper}
                            setOrderList={setOrderList}
                            currentProductId={testproductId}
                            backBtnCallback={hide ? (_e) => hide() : undefined}
                            isSelected={isSelected}
                            openExampleReportCB={openExampleReportCB}
                            showAddToOrderBtn={showAddToOrderBtn}
                        />
                        <CollapsibleTabRotatingIcon heading={<FormattedMessage id="templating.test-product.learn-more" defaultMessage="Learn more" />}>
                            <CardContent>
                                <Container classes={{ root: classes.divGridStyle }}>
                                    <div>
                                        <HeaderSubheaderTemplate
                                            heading={<FormattedMessage id="templating.test-product.name" defaultMessage="Product name" />}
                                            subheading={dataObj?.name}
                                            withWrapper={false}
                                        />
                                        {dataObj?.private &&
                                            dataObj.availableToOrganizations?.map((org) => (
                                                <Typography variant="body2" align="left" key={org.id}>
                                                    Offered by {org.name}
                                                </Typography>
                                            ))}
                                    </div>
                                    <HeaderSubheaderTemplate
                                        heading={<FormattedMessage id="templating.test-product.product-category" defaultMessage="Product category" />}
                                        subheading={dataObj?.productCategory}
                                    />
                                    <HeaderSubheaderTemplate
                                        heading={<FormattedMessage id="templating.test-product.product-code" defaultMessage="Product code" />}
                                        subheading={dataObj?.productCode}
                                    />
                                    <HeaderSubheaderTemplate
                                        heading={<FormattedMessage id="templating.test-product.upc-code" defaultMessage="UPC Code" />}
                                        subheading={dataObj?.upccode}
                                    />
                                    {dataObj?.otherProductCodes && <OtherProductCodesTemplate dto={dataObj!.otherProductCodes} />}
                                    {dataObj?.governmentReporting ? (
                                        <HeaderSubheaderTemplate
                                            heading={
                                                <FormattedMessage
                                                    id="templating.test-product.gov-reporting"
                                                    defaultMessage="Gov’t agency reporting (of results) is required."
                                                />
                                            }
                                        />
                                    ) : null}

                                    {dataObj?.unavailableIn?.length ? (
                                        <div>
                                            <HeaderSubheaderTemplate
                                                heading={
                                                    <FormattedMessage
                                                        id="templating.test-product.unavailable-locations"
                                                        defaultMessage="Unavailable in the following locations:"
                                                    />
                                                }
                                                withWrapper={false}
                                            />
                                            {dataObj?.unavailableIn.map((e) => (
                                                <Typography variant="body2" align="left" key={e.id}>
                                                    {e.name}
                                                </Typography>
                                            ))}
                                        </div>
                                    ) : null}
                                </Container>
                            </CardContent>
                        </CollapsibleTabRotatingIcon>
                        <CollapsibleTabRotatingIcon heading={<FormattedMessage id="templating.test-product.faq" defaultMessage="FAQ" />}>
                            <CardContent>
                                <Container classes={{ root: classes.divGridStyle }}>
                                    <div>
                                        <Typography variant="body2" align="left" sx={{ whiteSpace: "pre-wrap" }}>
                                            {dataObj?.frequentlyAskedQuestions}
                                        </Typography>
                                    </div>
                                </Container>
                            </CardContent>
                        </CollapsibleTabRotatingIcon>
                        {dataObj?.labTestOrdered && (
                            <CollapsibleTabRotatingIcon heading={<FormattedMessage id="templating.test-product.test-details" defaultMessage="Test details" />}>
                                <CardContent sx={{ padding: 0, "&:last-child": { paddingBottom: 0 } }}>
                                    {/* //certificationType, loincCode */}
                                    {/*
                                // @ts-ignore */}
                                    <TestOfferedDetails minimalMode dto={dataObj?.labTestOrdered.detailedData} />
                                </CardContent>
                            </CollapsibleTabRotatingIcon>
                        )}
                        {dataObj?.supplyProductOrdered && (
                            <CollapsibleTabRotatingIcon
                                heading={<FormattedMessage id="templating.test-product.sampling-kit-details" defaultMessage="Collection device details" />}
                            >
                                <CardContent sx={{ padding: 0, "&:last-child": { paddingBottom: 0 } }}>
                                    <ProductDetailsHeader
                                        minimalMode
                                        dto={{
                                            name: dataObj?.supplyProductOrdered.name,
                                            category: dataObj?.supplyProductOrdered.detailedData?.productCategory,
                                            description: dataObj?.supplyProductOrdered.detailedData?.description,
                                            paymentMethods: [PAYMENT_TYPE.CASH],
                                            imageUrl: dataObj?.supplyProductOrdered?.img,
                                        }}
                                    />
                                    <CollapsibleTabRotatingIcon
                                        heading={<FormattedMessage id="templating.test-product.sampling-kit" defaultMessage="Collection device" />}
                                    >
                                        <CardContent>
                                            <Container classes={{ root: classes.divGridStyle }}>
                                                <HeaderSubheaderTemplate
                                                    heading={
                                                        <FormattedMessage
                                                            id="templating.test-product.sampling-kit-name"
                                                            defaultMessage="Collection device name"
                                                        />
                                                    }
                                                    subheading={dataObj?.supplyProductOrdered.name}
                                                />
                                                <HeaderSubheaderTemplate
                                                    heading={
                                                        <FormattedMessage
                                                            id="templating.test-product.sampling-kit-category"
                                                            defaultMessage="Collection device category"
                                                        />
                                                    }
                                                    subheading={dataObj?.supplyProductOrdered.detailedData?.productCategory}
                                                />
                                                <HeaderSubheaderTemplate heading={`Offered by ${dataObj?.supplyProductOrdered.orgName}`} />
                                                <HeaderSubheaderTemplate
                                                    heading={<FormattedMessage id="templating.test-product.sku" defaultMessage="SKU" />}
                                                    subheading={dataObj?.supplyProductOrdered.detailedData?.sku}
                                                />
                                                <HeaderSubheaderTemplate
                                                    heading={<FormattedMessage id="templating.test-product.upc-code" defaultMessage="UPC Code" />}
                                                    subheading={dataObj?.supplyProductOrdered.detailedData?.upcCode}
                                                />
                                                <OtherProductCodesTemplate dto={dataObj?.supplyProductOrdered.detailedData?.otherProductCodes} />
                                            </Container>
                                        </CardContent>
                                    </CollapsibleTabRotatingIcon>
                                </CardContent>
                            </CollapsibleTabRotatingIcon>
                        )}
                    </CardContent>
                )}
            </Card>
        </StyledGrid>
    );
};

export default TestProductDetails;
