export const DefinedAxiosInstances = {
    V1: {
        name: "V1",
        urlPostFix: "/v1",
    },
    V2: {
        name: "V2",
        urlPostFix: "/v2",
    },
    V3: {
        name: "V3",
        urlPostFix: "/v3",
    },
    graphqlInstance: {
        name: "graphqlInstance",
    },
    NOV: {
        name: "NOV",
        urlPostFix: "/",
    },
    AUTH: {
        name: "AUTH",
        urlPostFix: "auth",
    },
};
