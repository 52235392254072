import { AxiosRequestConfig } from "axios";
import { GRID_IDENTIFIERS, GridConfigCache, GridConfigItem } from "../../types";
import {
    apiDeleteGridConfig,
    createGridConfig,
    getGridConfigById,
    getGridConfigListByIdentifier,
    updateGridConfigById,
} from "../services/GridConfigAPIService";
import store from "../../../store";

class GridConfigManager {
    private cache: GridConfigCache;

    constructor(container: GridConfigCache) {
        this.cache = container;
    }

    async getGridConfigsByIdentifier(identifier: `${GRID_IDENTIFIERS}`, config: AxiosRequestConfig = {}, spinner: boolean = false) {
        const response = await getGridConfigListByIdentifier(identifier, config, spinner);

        const configMap = Object.assign({}, ...response.data.data.map(GridConfigManager.processPlaceholders));

        this.cache[identifier].map = { ...this.cache[identifier].map, ...configMap };

        return response;
    }

    async getSpecificGridConfig(identifier: `${GRID_IDENTIFIERS}`, configId: number): Promise<GridConfigItem> {
        if (this.cache[identifier].map[configId]) {
            return Promise.resolve(this.cache[identifier].map[configId]);
        }

        const configRes = await getGridConfigById(identifier, configId);
        const configListData = configRes.data;

        if (configListData.totalElements === 0) {
            return Promise.resolve({
                id: -1,
                name: `Default config ${identifier}`,
                configuration: {
                    pivotMode: false,
                    columnState: [],
                    columnGroupState: [],
                    filterModel: {},
                },
                updated: new Date(),
            } as GridConfigItem);
        }

        const configItem = configListData.data.map(GridConfigManager.processPlaceholders)[0][configId];

        this.cache[identifier].map[configId] = configItem;

        return Promise.resolve(this.cache[identifier].map[configId]);
    }

    async saveGridConfig(identifier: `${GRID_IDENTIFIERS}`, currentConfig: GridConfigItem) {
        if (this.cache[identifier].map[currentConfig.id] && currentConfig.id > 0) {
            await updateGridConfigById(currentConfig.id, currentConfig);

            this.cache[identifier].map[currentConfig.id] = currentConfig;
            return currentConfig;
        }

        const newItem = await createGridConfig(identifier, currentConfig);

        this.cache[identifier].map[newItem.id] = { ...currentConfig, id: newItem.id };
        return newItem;
    }

    async deleteGridConfig(identifier: `${GRID_IDENTIFIERS}`, configId: number) {
        await apiDeleteGridConfig(configId);

        if (this.cache[identifier].map[configId]) {
            delete this.cache[identifier].map[configId];
        }
    }

    static processPlaceholders(config: GridConfigItem) {
        if (!config.isGlobal) {
            return { [config.id]: config };
        }

        // @ts-ignore
        const filterModelObject = config.configuration.filterModel || {};
        const fieldListEntries = Object.entries<any>(filterModelObject);

        if (fieldListEntries.length === 0) {
            return { [config.id]: config };
        }

        fieldListEntries.forEach(([_, filterConfig]) => {
            const { filter } = filterConfig;

            if (typeof filter === "string" && filter.includes("{{currentOrganizationId}}")) {
                Object.assign(filterConfig, { filter: store.getState().userReducer.tenant.organization.id });
            }
        });

        return { [config.id]: config };
    }
}

export default GridConfigManager;
