import { styled } from "@mui/material/styles";
import { Box, BoxProps } from "@mui/material";
import OneHealthDarkBGLogo from "../../assets/icons/1health-ico-dark-rounded.svg";

interface StyledBoxProps extends BoxProps {
    isSubdomain?: boolean;
}

const PREFIX = "GenericPublicPageWrapper";

export const classes = {
    pageWrapper: `${PREFIX}-pageWrapper`,
    leftSidePageWrapper: `${PREFIX}-leftSidePageWrapper`,
    rightSidePageWrapper: `${PREFIX}-rightSidePageWrapper`,
};

export const StyledBox = styled(Box, { shouldForwardProp: (prop) => prop !== "isSubdomain" })<StyledBoxProps>(({ isSubdomain }) => ({
    [`&.${classes.pageWrapper}`]: {
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        height: isSubdomain ? "95vh" : "100vh",
        "& > div:first-of-type": {
            position: "fixed",
        },
        "& > div:last-child": {
            marginLeft: isSubdomain ? "unset" : "50%",
            // marginLeft: "50%",
        },
        "@media(max-width:959px)": {
            "& > div:last-child": {
                marginLeft: "0%",
            },
        },
        "@media (min-width:960px) and (max-width:1200px)": {
            "& > div:last-child": {
                // marginLeft: "42%",
                marginLeft: isSubdomain ? "unset" : "42%",
            },
        },
    },
    [`& .${classes.leftSidePageWrapper}`]: {
        minHeight: isSubdomain ? "95vh" : "100vh",
        width: "50%",
        // display: "flex",
        display: isSubdomain ? "none" : "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#464B52",
        backgroundImage: `url(${OneHealthDarkBGLogo})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "341px 341px !important",
        backgroundPosition: "top -8% center !important",
        "@media(max-width:959px)": {
            display: "none",
        },
        "@media(min-width:960px)": {
            backgroundSize: "800px",
            backgroundPosition: "left 85% top 100%",
        },
        "@media(min-width:1200px)": {
            backgroundSize: "1000px",
            backgroundPosition: "left 85% top 100%",
        },
        "@media(min-width:1600px)": {
            backgroundSize: "1500px",
            backgroundPosition: "left 90% top 100%",
        },
        "@media(min-width:3015px)": {
            backgroundSize: "2800px",
            backgroundPosition: "left 100% top 100%",
        },
        "@media (min-width:960px) and (max-width:1200px)": {
            width: "42%",
            paddingLeft: "2rem",
            paddingRight: ".2rem",
        },
    },

    [`& .${classes.rightSidePageWrapper}`]: {
        display: "flex",
        // width: "50%",
        width: isSubdomain ? "100%" : "50%",
        minHeight: isSubdomain ? "95vh" : "100vh",
        alignItems: "center",
        justifyContent: "center",
        background: "white",
        "@media(max-width:959px)": {
            width: "100%",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "initial",
            // marginTop: 2,
        },
        "@media (min-width:960px) and (max-width:1200px)": {
            // width: "58%",
            width: isSubdomain ? "100%" : "58%",
        },
    },
}));
