import { AxiosRequestConfig } from "axios";
import AxiosActions from "../../../config/axios/AxiosActions";
import { getAxiosInstances } from "../../../utils/AppUtils";
import { GRID_IDENTIFIERS, GridConfigItem } from "../../types";

export function getGridConfigById(identifier: `${GRID_IDENTIFIERS}`, id: number, config: AxiosRequestConfig = {}, spinner: boolean = false) {
    return AxiosActions.get(
        getAxiosInstances().V2,
        `/grid-config`,
        {
            ...config,
            params: { ...config.params, gridIdentifier: identifier, id },
        },
        spinner,
    );
}

export function getGridConfigListByIdentifier(identifier: `${GRID_IDENTIFIERS}`, config: AxiosRequestConfig = {}, spinner: boolean = false) {
    return AxiosActions.get(
        getAxiosInstances().V2,
        `/grid-config`,
        {
            ...config,
            params: { ...config.params, gridIdentifier: identifier },
        },
        spinner,
    );
}

export function createGridConfig(
    identifier: `${GRID_IDENTIFIERS}`,
    dto: GridConfigItem,
    config: AxiosRequestConfig = {},
    spinner: boolean = false,
): Promise<GridConfigItem> {
    const data = {
        gridIdentifier: identifier,
        name: dto.name,
        configuration: JSON.stringify(dto.configuration),
    };

    return AxiosActions.post(getAxiosInstances().V2, "/grid-config", data, config, spinner).then((res) => {
        return {
            id: res.data.id,
            gridIdentifier: identifier,
            name: dto.name,
            configuration: dto.configuration,
            isGlobal: res.data?.isGlobal,
            updated: res.data?.updated,
        };
    });
}

export function updateGridConfigById(id: number | string, dto: GridConfigItem, config: AxiosRequestConfig = {}, spinner: boolean = false) {
    const dataObject = {
        ...dto,
        configuration: JSON.stringify(dto.configuration),
    };

    return AxiosActions.put(getAxiosInstances().V2, `/grid-config/${id}`, dataObject, config, spinner);
}

export function apiDeleteGridConfig(id: number, config: AxiosRequestConfig = {}, spinner: boolean = false) {
    return AxiosActions.delete(getAxiosInstances().V2, `/grid-config/${id}`, config, spinner);
}
