import { AxiosRequestConfig, AxiosResponse } from "axios";
import { getAxiosInstances } from "../../../../utils/AppUtils";
import AxiosActions from "../../AxiosActions";
import { OrganizationRole } from "../../../../utils/appEnums/AppEnums";
import { TListApiResponse } from "../commonTypes";
import { TNotificationDto } from "../../../../views/privatePages/PartnershipManagement/components/common/utils";

export const createHealthcareProvider = (dto, key: string, config?: any, spinner: boolean = true): Promise<AxiosResponse> => {
    return AxiosActions.post(getAxiosInstances().V2, `public/organization?key=${key}`, dto, { ...config }, spinner);
};

export const registerPartner = (dto, key: string, config?: any, spinner: boolean = true): Promise<AxiosResponse> => {
    return AxiosActions.post(
        getAxiosInstances().V2,
        "public/organization",
        dto,
        { ...config, params: { ...config.params, key }, withCredentials: false },
        spinner,
    );
};

export const getOrdersByOrgType = (type: OrganizationRole, patientId: number | null, config?: any, spinner: boolean = true): Promise<AxiosResponse> => {
    return AxiosActions.get(getAxiosInstances().V2, `/organization/type/${type}/order${patientId ? `?patientId=${patientId}` : ""}`, { ...config }, spinner);
};
export const getOrdersResulted = (config?: any, spinner: boolean = true): Promise<AxiosResponse> => {
    return AxiosActions.get(getAxiosInstances().V2, `/organization/order-results`, { ...config }, spinner);
};

export const getMasterOrdersInTenantContext = (type: `${OrganizationRole}`, config?: any, spinner: boolean = false): Promise<AxiosResponse> => {
    return AxiosActions.get(getAxiosInstances().V2, `/organization/type/${type}/master-order`, { ...config }, spinner);
};
export const getLabResultsByOrganizationEncounter = (orgType: string, patientId: number, config: any): Promise<AxiosResponse> => {
    return AxiosActions.get(getAxiosInstances().V2, `/organization/type/${orgType}/encounter/list?personId=${patientId}`, { ...config }, true);
};

export const getEncounterList = (orgType: string, config: any): Promise<AxiosResponse> => {
    return AxiosActions.get(getAxiosInstances().V2, `/organization/type/${orgType}/encounter/list`, config, true);
};
export const orderFromOrganizations = (type: string, config?: any): Promise<AxiosResponse> => {
    return AxiosActions.get(getAxiosInstances().V2, `/organization/type/${type}/product-partners`, { ...config }, true);
};
export const getTenantAnalytics = (dto, config): Promise<AxiosResponse> => {
    return AxiosActions.post(getAxiosInstances().V2, `/organization/dashboard`, dto, { ...config }, true);
};

export const getTenantExportData = (dto, config: AxiosRequestConfig): Promise<AxiosResponse> => {
    return AxiosActions.post(getAxiosInstances().V2, `/organization/dashboard/export`, dto, config, true);
};
export const getOrganizationPartnerships = (config: AxiosRequestConfig, spinner = true): Promise<AxiosResponse<TListApiResponse<any>>> => {
    return AxiosActions.get(getAxiosInstances().V2, "/organization/partnership", config, spinner);
};

export enum PARTNER_IDENTIFIER {
    AUTHORIZATION_CODE = "authorizationCode",
    ORGANIZATION_ID = "organizationId",
}

export const createPartnershipRequest = (
    dto: {
        authorizationCode?: string;
        organizationId?: number;
        orderingActive: boolean;
        serviceProviderActive: boolean;
    },
    config = {},
    spinner = true,
): Promise<AxiosResponse> => {
    return AxiosActions.post(getAxiosInstances().V2, `/organization/partnership/request`, dto, config, spinner);
};

export const editOrganizationPartnershipStatus = (orgId: string | number, config: AxiosRequestConfig = {}, spinner = true): Promise<AxiosResponse> => {
    return AxiosActions.put(getAxiosInstances().V2, `/organization/partnership/${orgId}/status`, {}, config, spinner);
};

export const editOrganizationServiceStatus = (
    orgId: string | number,
    service: string,
    config: AxiosRequestConfig = {},
    spinner = true,
): Promise<AxiosResponse> => {
    return AxiosActions.put(getAxiosInstances().V2, `/organization/partnership/${orgId}/service/${service}`, {}, config, spinner);
};

// export const listPartnerOrganizationsByType = (config: AxiosRequestConfig, spinner = false) => {
//     return AxiosActions.get(getAxiosInstances().V2, `organization/can-order-from`, config, spinner);
// };

// export const ordersToDoByOrganization = (type: OrganizationRole, config?: any, spinner: boolean = true): Promise<AxiosResponse> => {
//     return AxiosActions.get(getAxiosInstances().V2, `/organization/type/${type}/order/to-do/list`, { ...config }, spinner);
// };

// export const partnerOrderList = (type: OrganizationRole, config?: any, spinner: boolean = true): Promise<AxiosResponse> => {
//     return AxiosActions.get(getAxiosInstances().V2, `/organization/type/${type}/partner-order/list`, { ...config }, spinner);
// };

export const getOrganizationProvidersFromPartnerships = (gtmOrgId: number, config: AxiosRequestConfig, spinner = true): Promise<AxiosResponse> => {
    return AxiosActions.get(getAxiosInstances().V2, `/organization/${gtmOrgId}/partnership/any-service`, config, spinner);
};
export const getAllUsersForSpecificTenant = (fullTextSearchOnPerson: string, page: number, size: number, config): Promise<AxiosResponse> => {
    // const fulltextSearch = fullTextSearchOnPerson ? `fullTextSearchOnPerson=${fullTextSearchOnPerson}&` : "";
    return AxiosActions.post(
        getAxiosInstances().V2,
        `/health/organization/patient`, // ?${fulltextSearch}page=${page}&size=${size}
        {},
        {
            withCredentials: true,
            params: {
                fullTextSearchOnPerson,
                page,
                size,
            },
            ...config,
        },
        false,
    );
};

export const invitePartner = (dto: any): Promise<AxiosResponse> => {
    return AxiosActions.post(getAxiosInstances().V2, `/organization/partner/invite`, dto, {}, true);
};

export const getPatientsOfCurrentOrganization = (dto, config: AxiosRequestConfig, spinner: boolean = false): Promise<AxiosResponse> => {
    return AxiosActions.post(getAxiosInstances().V3, `/health/grid/patient`, dto, config, spinner);
};

export const getPartnerSysAdminInfo = (partnerId: number | string, config: AxiosRequestConfig = {}, spinner = true): Promise<AxiosResponse> => {
    return AxiosActions.get(getAxiosInstances().V2, `/organization/${partnerId}`, config, spinner);
};

export const sendPartnerContactNotification = (
    partnerId: number | string,
    contactPointId: number | string,
    dto: TNotificationDto,
    config: AxiosRequestConfig = {},
    spinner: boolean = true,
): Promise<AxiosResponse<any>> => {
    return AxiosActions.post(getAxiosInstances().V2, `/organization/partner/${partnerId}/contact-point/${contactPointId}/send`, dto, config, spinner);
};

export const createPartnershipContactPoint = (
    partnerId: number | string,
    dto: any,
    config: AxiosRequestConfig = {},
    spinner: boolean = true,
): Promise<AxiosResponse<any>> => {
    return AxiosActions.post(getAxiosInstances().V2, `/organization/partner/${partnerId}/contact-point`, dto, config, spinner);
};

export const getOrganizationsList = (name: string, address: string, isClaimed: boolean, page: number, size: number, config): Promise<AxiosResponse> => {
    return AxiosActions.get(
        getAxiosInstances().V2,
        `/organization/list`,
        {
            withCredentials: true,
            params: {
                name,
                address,
                isClaimed,
                page,
                size,
            },
            ...config,
        },
        false,
    );
};

export const updatePartnerOrganization = (
    id: number,
    data: { name?: string; tin?: string; headquarterAddress?: any; clia?: string; npiId?: number },
    config: AxiosRequestConfig = {},
    spinner = true,
): Promise<AxiosResponse> => {
    return AxiosActions.put(getAxiosInstances().V2, `organization/partner/${id}`, data, config, spinner);
};

export const createPartnerOrganization = (
    dto: {
        name: string;
        headquarterAddress: any;
        tin?: string;
        clia?: string;
        npiId?: number;
    },
    config = {},
    spinner: boolean = true,
): Promise<AxiosResponse> => {
    return AxiosActions.post(getAxiosInstances().V2, `organization/partner/invitation`, dto, config, spinner);
};

export const previewFaxTemplateNotification = (
    partnerId: number | string,
    contactPointId: number | string,
    dto: TNotificationDto, // TFaxNotificationDto
    config: AxiosRequestConfig = {},
    spinner: boolean = true,
): Promise<AxiosResponse<any>> => {
    return AxiosActions.post(getAxiosInstances().V2, `/organization/partner/${partnerId}/contact-point/${contactPointId}/preview`, dto, config, spinner);
};
