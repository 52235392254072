import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Box, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import GenericPublicPageWrapper from "../../../components/GenericPublicPageWrapper/GenericPublicPageWrapper";
import OrganizationCreateFrom from "./components/OrganizationCreateFrom/OrganizationCreateFrom";
import Branding from "./components/Branding";
import { boxStyle } from "../../../AppGlobalStyles";
import { StyledMobileViewWrapper } from "./styles/styles";
import Forbidden from "../../staticPages/Forbidden/Forbidden";

const NonLoggedInCreateOrganization = () => {
    const [isOrgCreated, setIsOrgCreated] = useState(false);
    const [searchParams] = useSearchParams();
    const productParam = searchParams.get("product");

    const handleOrgCreated = (value: boolean) => {
        setIsOrgCreated(value);
    };

    const SuccessfulRegisteredOrg = (
        <Box sx={{ ...boxStyle, flexDirection: "column", width: "80%" }}>
            <Typography variant="h2" sx={{ fontSize: "1.25rem", fontWeight: 700, marginTop: "3.5rem !important" }}>
                <FormattedMessage
                    id="non-logged.create-organization-check-email"
                    defaultMessage="Your System Administrator should check their {br} email!"
                    values={{ br: <br /> }}
                />
            </Typography>
            <Typography sx={{ fontSize: "1rem" }}>
                <FormattedMessage
                    id="non-logged.create-organization-email-link"
                    defaultMessage="They will have a link to set their password. After doing so,{br} they can log in and start using 1health!"
                    values={{ br: <br /> }}
                />
            </Typography>
        </Box>
    );
    const RightSide = !isOrgCreated ? <OrganizationCreateFrom createOrg={handleOrgCreated} /> : SuccessfulRegisteredOrg;
    const RightSideMobileLayout = (
        <StyledMobileViewWrapper>
            <Branding hideSetupLabel={isOrgCreated} />
        </StyledMobileViewWrapper>
    );

    if (!productParam) {
        return <Forbidden />;
    }

    return (
        <GenericPublicPageWrapper
            isSubdomain={false}
            left={<Branding hideSetupLabel={isOrgCreated} />}
            right={
                <>
                    {RightSideMobileLayout}
                    {RightSide}
                </>
            }
        />
    );
};
export default NonLoggedInCreateOrganization;
