import { AxiosResponse } from "axios";
import { getAxiosInstances } from "../../../../utils/AppUtils";
import AxiosActions from "../../AxiosActions";

export const postNewTestResultDefinition = (testOfferedId: number, dto: any): Promise<AxiosResponse> => {
    return AxiosActions.post(getAxiosInstances().V2, `/health/compendium/${testOfferedId}/result`, dto, { withCredentials: true }, true);
};

export const updateTestResultDefinition = (testOfferedId: number, testResultDefinitionId: number, dto: any): Promise<AxiosResponse> => {
    return AxiosActions.put(
        getAxiosInstances().V2,
        `/health/compendium/${testOfferedId}/result/${testResultDefinitionId}`,
        dto,
        { withCredentials: true },
        true,
    );
};

export const getTestResultDefinitions = (testOfferedId: number, config?: any): Promise<AxiosResponse> => {
    return AxiosActions.post(
        getAxiosInstances().V2,
        `/health/compendium/${testOfferedId}/result-definition`,
        { page: 0, size: 1000 }, // 1000 is max acceptable value, related to NG-1796
        { withCredentials: true, ...config },
        true,
    );
};

export const getTestResultDefinition = (testOfferedId: number, testResultDefId: number, config?: any): Promise<AxiosResponse> => {
    return AxiosActions.get(
        getAxiosInstances().V2,
        `/health/compendium/${testOfferedId}/result/${testResultDefId}`,
        { withCredentials: true, ...config },
        true,
    );
};

export const deleteTestResultDefinition = (testOfferedId: number, id: number): Promise<AxiosResponse> => {
    return AxiosActions.delete(getAxiosInstances().V2, `/health/compendium/${testOfferedId}/result/${id}`, { withCredentials: true }, true);
};

export const uploadOldTestResult = (dto: any, files: File[]): Promise<AxiosResponse> => {
    const formData = new FormData();
    formData.append("dto", JSON.stringify(dto));
    files.forEach((element) => formData.append("files", element));

    return AxiosActions.post(getAxiosInstances().V2, `/health/encounter/old-result/data-file`, formData, {}, true);
};
