import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import unionBy from "lodash/unionBy";
import { Item } from "../../views/privatePages/ProductGroupManagement/ProductGroupManagementTypes";

export interface ProductItemsState {
    productItems: Item[];
}

const initialState: ProductItemsState = {
    productItems: [],
};

export const productSlice = createSlice({
    name: "products",
    initialState,
    reducers: {
        fetchProductItems: (state, action: PayloadAction<Item[]>) => {
            state.productItems = action.payload;
        },
        addProductItems: (state, action: PayloadAction<Item[]>) => {
            state.productItems = unionBy(state.productItems, action.payload, "id");
        },
        updateProductItems: (state, action: PayloadAction<Item[]>) => {
            state.productItems = action.payload;
        },
        removeProductsItems: (state) => {
            state.productItems = state.productItems.filter((product) => product.checked === false);
        },
    },
});

export const { fetchProductItems, addProductItems, updateProductItems, removeProductsItems } = productSlice.actions;
export default productSlice.reducer;
