const hasOwnPolyfill = () => {
    if (!Object.hasOwn) {
        Object.hasOwn = (obj, prop) => {
            return Object.prototype.hasOwnProperty.call(obj, prop);
        };
    }
};

export const registerAllPolyfills = () => {
    hasOwnPolyfill();
};
