import { FC, ReactNode } from "react";
import { Tooltip, TooltipProps } from "@mui/material";
import { HelpSharp } from "@mui/icons-material";
import { styled } from "@mui/material/styles";

type placement = "left" | "right";
interface TooltipWrapperProps {
    title?: string | ReactNode;
    placement?;
    forTab?: boolean;
    setIconPosition?: boolean;
    topPosition?: string | number;
    rightPosition?: string | number;
    children?: ReactNode;
}
interface TooltipStyledProps extends TooltipProps {
    tooltipIconPlacement?: placement;
    forTab?: boolean;
    setIconPosition?: boolean;
    topPosition?: string | number;
    rightPosition?: string | number;
}
const Wrapper = styled("div")(() => ({
    width: "inherit",
    position: "relative",
    "& .MuiFormControl-root": {
        marginTop: "0 !important",
    },
}));
const TooltipStyled = styled(Tooltip, {
    shouldForwardProp: (prop) =>
        prop !== "tooltipIconPlacement" && prop !== "forTab" && prop !== "setIconPosition" && prop !== "topPosition" && prop !== "rightPosition",
})<TooltipStyledProps>(({ theme, tooltipIconPlacement, forTab, setIconPosition, topPosition, rightPosition }) => {
    const hPosition = tooltipIconPlacement === "left" ? { left: -8 } : { right: -8 };
    const hPositionTab = tooltipIconPlacement === "left" ? { left: 0 } : { right: 0 };
    const isTabTopPos = forTab ? 0 : -8;
    const isTabHorPos = forTab ? hPositionTab : hPosition;

    return {
        fontSize: 18,
        zIndex: 99,
        backgroundColor: theme.palette.common.white,
        color: theme.palette.secondary.main,
        borderRadius: "50%",
        cursor: "pointer",
        position: "absolute",
        top: setIconPosition ? topPosition || 0 : isTabTopPos,
        ...(setIconPosition ? { right: rightPosition || 0 } : isTabHorPos),
    };
});
export const TooltipWrapper: FC<TooltipWrapperProps> = (props) => {
    const { title, placement = "left", forTab, setIconPosition, topPosition, rightPosition, children } = props;

    // TODO: Accept localization key and default message.
    // First check for title and if not provided then check for the localization key.

    return (
        <Wrapper>
            {title && (
                <TooltipStyled
                    title={title}
                    setIconPosition={setIconPosition || undefined}
                    topPosition={topPosition || undefined}
                    rightPosition={rightPosition || undefined}
                    placement={placement || "top-start"}
                    tooltipIconPlacement={placement}
                    forTab={forTab}
                    arrow
                >
                    <HelpSharp />
                </TooltipStyled>
            )}
            {children}
        </Wrapper>
    );
};
