import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface IThemeProps {
    data: any;
    appBackgroundStyle: React.CSSProperties;
    publicUrlLogo: string;
    publicBackgroundStyle: React.CSSProperties;
    messageBackground: string;
}

interface NewGridData {
    startRow?: number;
    endRow?: number;
}
export interface UiReducerState {
    themeProps: IThemeProps;
    tenantLogoUrl: string | null;
    tenantTitleIconUrl: string | null;
    tenantDarkLogoUrl: string | null;
    tenantDarkTitleIconUrl: string | null;
    showSidebar: boolean;
    mobileOpen: boolean;
    showHeader: boolean;
    activePageName: string;
    googlePlacesApiKey: string;
    refreshGrid?: boolean;
    newGridData?: NewGridData;
    activeUiTabResourceId?: string;
    showBlockingModal: boolean;
    publicTenantData: any;
    refreshMyWorkflowList?: boolean;
    subdomain: string;
    refreshActionItems?: boolean;
}

const initialState: UiReducerState = {
    showSidebar: true,
    mobileOpen: false,
    showHeader: false,
    themeProps: {
        data: {},
        appBackgroundStyle: {
            backgroundImage: "",
            backgroundColor: "black",
        },
        publicUrlLogo: "",
        publicBackgroundStyle: {
            backgroundImage: "",
            backgroundColor: "black",
        },
        messageBackground: "",
    },
    tenantLogoUrl: null,
    tenantTitleIconUrl: null,
    tenantDarkLogoUrl: null,
    tenantDarkTitleIconUrl: null,
    activePageName: "",
    googlePlacesApiKey: "",
    activeUiTabResourceId: "",
    showBlockingModal: false,
    publicTenantData: {},
    refreshMyWorkflowList: true,
    subdomain: "",
    refreshActionItems: false,
};

const uiSlice = createSlice({
    initialState,
    name: "uiSlice",
    reducers: {
        mobileOpen(state, action: PayloadAction<boolean>) {
            return { ...state, mobileOpen: action.payload };
        },
        showSidebar(state, action: PayloadAction<boolean>) {
            return { ...state, showSidebar: action.payload };
        },
        toggleSidebar(state) {
            return { ...state, showSidebar: !state.showSidebar };
        },
        showHeader(state, action: PayloadAction<boolean>) {
            return { ...state, showHeader: action.payload };
        },
        setSubdomain(state, action: PayloadAction<string>) {
            return { ...state, subdomain: action.payload };
        },
        showBlockingModal(state, action: PayloadAction<boolean>) {
            return { ...state, showBlockingModal: action.payload };
        },
        forceRefreshMyWorkflows(state) {
            return { ...state, refreshMyWorkflowList: !state.refreshMyWorkflowList };
        },
        setTenantLogoUrl(state, action: PayloadAction<string | null>) {
            return { ...state, tenantLogoUrl: action.payload };
        },
        setTenantTitleIconUrl(state, action: PayloadAction<string | null>) {
            return { ...state, tenantTitleIconUrl: action.payload };
        },
        setTenantDarkLogoUrl(state, action: PayloadAction<string | null>) {
            return { ...state, tenantDarkLogoUrl: action.payload };
        },
        setTenantDarkTitleIconUrl(state, action: PayloadAction<string | null>) {
            return { ...state, tenantDarkTitleIconUrl: action.payload };
        },
        setNewGridDataFetched(state, action: PayloadAction<NewGridData>) {
            return { ...state, newGridData: action.payload };
        },
        setForceRefreshGrid(state, action: PayloadAction<boolean>) {
            return { ...state, refreshGrid: action.payload };
        },
        // todo: disabled with NG-1345; consider removal
        setActiveUiTabResourceId(state, action: PayloadAction<string>) {
            return { ...state, activeUiTabResourceId: action.payload };
        },
        setGooglePlacesApiKey(state, action: PayloadAction<string>) {
            return { ...state, googlePlacesApiKey: action.payload };
        },
        // todo: disabled with NG-1345; consider removal
        setActivePageName(state, action: PayloadAction<string>) {
            return { ...state, activePageName: action.payload };
        },
        // todo: not consumed; consider removal
        setThemeProps(state, action: PayloadAction<IThemeProps>) {
            return { ...state, themeProps: action.payload };
        },
        setPublicTenantData(state, action: PayloadAction<any>) {
            return { ...state, publicTenantData: action.payload };
        },
        setRefreshActionItems(state) {
            return { ...state, refreshActionItems: !state.refreshActionItems };
        },
    },
});

export const uiActions = uiSlice.actions;
export default uiSlice.reducer;
