import { DetailedHTMLProps, FC, IframeHTMLAttributes, useEffect, useState } from "react";
import { resizeIframe } from "./injectedScript.js";

interface ResponsiveIframeProps extends Omit<DetailedHTMLProps<IframeHTMLAttributes<HTMLIFrameElement>, HTMLIFrameElement>, "src"> {
    srcDoc: string;
}

const processDocument = (htmlString: string): string => {
    const parser = new DOMParser();
    const tempDocument = parser.parseFromString(htmlString, "text/html");

    const script = document.createElement("script");
    script.innerHTML = `(${resizeIframe})()`;

    const head = tempDocument.querySelector("head");
    head?.appendChild(script);

    return tempDocument.documentElement.outerHTML;
};

const ResponsiveIframe: FC<ResponsiveIframeProps> = ({ srcDoc, style, ...rest }) => {
    const [iframeHeight, setIframeHeight] = useState<number>(0);
    const [injectedDocument, setInjectedDocument] = useState<string>("");

    useEffect(() => {
        setInjectedDocument(processDocument(srcDoc || ""));
        return () => setInjectedDocument("");
    }, [srcDoc]);

    useEffect(() => {
        function onMessage(message) {
            if (message.origin !== window.location.origin) {
                return;
            }

            const { data } = message;
            if (data?.height && data?.width) {
                setIframeHeight(data.height);
            }
        }

        window.addEventListener("message", onMessage);

        return () => window.removeEventListener("message", onMessage);
    }, []);

    return (
        <iframe
            title="Preview"
            srcDoc={injectedDocument}
            style={{ top: 0, left: 0, width: "100%", height: iframeHeight || "100%", border: "none", ...style }}
            {...rest}
        />
    );
};

export default ResponsiveIframe;
