/* eslint-disable camelcase */
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import Cookies from "js-cookie";
import { getAccessToken, isAccessTokenPresent, isRefreshTokenPresent, serverLogOut, setTokenCookies } from "../../utils/AuthUtils/AuthUtils";
import store from "../../store";
import { PublicRoutes } from "../../utils/appEnums/AppEnums";
import { BO_CORE_DEFAULT_NUMBER_VALUE, isPathPublic, setAxiosInstances } from "../../utils/AppUtils";
import { API_BASE_URL, OAUTH_BASE_URL } from "../app.config";
import { DefinedAxiosInstances } from "../../utils/constant-data/Axios";
import { refreshAccessToken } from "./apis/auth/auth";
import { setSimpleSpinner } from "../../reducers/spinnerReducer";

interface Instance {
    name: string;
    config: AxiosRequestConfig;
}

let isAlreadyFetchingAccessToken = false;

// * debug
// export const AXIO_API_BASE_URL: string = `${API_BASE_URL?.replace("://", "://gosho.")}`;
export const AXIO_API_BASE_URL: string = `${API_BASE_URL}`;

export const cancelToken = axios.CancelToken.source();

export const cancelTokenModals = axios.CancelToken.source();

export const cancelTokenTimeOut: any = 0;

export const cancelTokenTimeOutModals: any = 0;

const instanceConfigs: Instance[] = [
    {
        name: DefinedAxiosInstances.V1.name,
        config: {
            baseURL: `${AXIO_API_BASE_URL}${DefinedAxiosInstances.V1.urlPostFix}`,
            cancelToken: cancelToken.token,
        },
    },
    {
        name: DefinedAxiosInstances.V2.name,
        config: {
            baseURL: `${AXIO_API_BASE_URL}${DefinedAxiosInstances.V2.urlPostFix}`,
            cancelToken: cancelToken.token,
        },
    },
    {
        name: DefinedAxiosInstances.V3.name,
        config: {
            baseURL: `${AXIO_API_BASE_URL}${DefinedAxiosInstances.V3.urlPostFix}`,
            cancelToken: cancelToken.token,
            headers: {
                "x-path": "v3",
            },
        },
    },
    {
        name: DefinedAxiosInstances.NOV.name,
        config: {
            baseURL: `${AXIO_API_BASE_URL}${DefinedAxiosInstances.NOV.urlPostFix}`,
            cancelToken: cancelToken.token,
        },
    },
    {
        name: DefinedAxiosInstances.AUTH.name,
        config: {
            baseURL: `${OAUTH_BASE_URL}${DefinedAxiosInstances.AUTH.urlPostFix}`,
            cancelToken: cancelToken.token,
        },
    },
    {
        name: DefinedAxiosInstances.graphqlInstance.name,
        config: {
            baseURL: `${AXIO_API_BASE_URL}`,
            withCredentials: true,
            headers: {
                "X-Requested-With": "XMLHttpRequest",
                "Time-Zone": Cookies.get("Time-Zone"),
            },
            cancelToken: cancelToken.token,
        },
    },
];

// static instances: AxiosInstances;

// refresh token related

let subscribers = [];

export function getAlreadyFetchingAcessToken() {
    return isAlreadyFetchingAccessToken;
}

export function setgetAlreadyFetchingAcessToken(value) {
    isAlreadyFetchingAccessToken = value;
}

export const addSubscriber = (callback: any) => {
    // @ts-ignore
    subscribers.push(callback);
};

export const onAccessTokenFetched = (access_token: string) => {
    // @ts-ignore
    subscribers = subscribers.filter((callback) => callback(access_token));
};

export const appendAuthorizationBearerToken = (config: any) => {
    const shallowConfig = config;
    const withCredentials = config.withCredentials ?? true;
    if (!withCredentials) {
        return config;
    }
    shallowConfig.headers.Authorization = `Bearer ${getAccessToken()}`;
    return shallowConfig;
};

const interceptorsConfig = (instance: AxiosInstance): void => {
    instance.interceptors.request.use(
        (config) => {
            return appendAuthorizationBearerToken(config);
        },
        (error) => Promise.reject(error),
    );

    instance.interceptors.response.use(
        (response) => response,
        (error) => {
            const shallowError = error;
            if (shallowError.message === "Network Error" || shallowError.message === "canceled") {
                return Promise.reject(shallowError);
            }

            const {
                config,
                response: { status },
            } = shallowError;

            const originalRequest = config;
            if (status === 401 && isRefreshTokenPresent()) {
                if (!getAlreadyFetchingAcessToken()) {
                    setgetAlreadyFetchingAcessToken(true);
                    refreshAccessToken()
                        .then((response) => {
                            setTokenCookies(
                                response.data.access_token,
                                response.data.refresh_token,
                                response.data.expires_in,
                                response.data.refresh_expires_in,
                            );
                            setgetAlreadyFetchingAcessToken(false);
                            onAccessTokenFetched(response.data.access_token);
                        })
                        .catch(() => {
                            serverLogOut().then(() => store.dispatch(setSimpleSpinner(false)));
                        });
                }

                return new Promise((resolve) => {
                    addSubscriber((access_token: any) => {
                        originalRequest.headers.Authorization = `Bearer ${access_token}`;
                        resolve(axios(originalRequest));
                    });
                });
            }

            if (status === 401 && !isRefreshTokenPresent() && !isAccessTokenPresent()) {
                if (!isPathPublic(window.location.pathname)) {
                    window.location.href = `${PublicRoutes.LOGIN}`;
                } else {
                    return Promise.reject(shallowError);
                }
            } else if (
                shallowError.request.responseType === "blob" &&
                shallowError.response.data instanceof Blob &&
                shallowError.response.data.type &&
                shallowError.response.data.type.toLowerCase().indexOf("json") !== BO_CORE_DEFAULT_NUMBER_VALUE
            ) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = () => {
                        if (typeof reader.result === "string") {
                            shallowError.response.data = JSON.parse(reader.result);
                        }
                        resolve(Promise.reject(shallowError));
                    };

                    reader.onerror = () => {
                        reject(shallowError);
                    };

                    reader.readAsText(shallowError.response.data);
                });
            } else {
                return Promise.reject(shallowError);
            }
            return undefined;
        },
    );
};

export const createInstances = (instances: Instance[]) => {
    const axiosInstances: any = {};
    instances.forEach((instance) => {
        axiosInstances[instance.name] = axios.create(instance.config);
        interceptorsConfig(axiosInstances[instance.name]);
    });
    setAxiosInstances({ ...axiosInstances });
};

export const initialize = () => {
    createInstances(instanceConfigs);
};

export const all = <T>(values: (T | Promise<T>)[]): Promise<T[]> => {
    return axios.all(values);
};

export const isHttpErrorMessageAvailable = (error: any): boolean => {
    return axios.isAxiosError(error) && !!error.response?.data?.message;
};
