import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IProgressSpinner {
    show: boolean;
    progress: number;
}

export interface SpinnerReducerState {
    showSpinner: boolean;
    progressSpinner: IProgressSpinner;
}

const initialState: SpinnerReducerState = {
    showSpinner: false,
    progressSpinner: {
        show: false,
        progress: 0,
    },
};

const spinnerSlice = createSlice({
    initialState,
    name: "spinnerSlice",
    reducers: {
        setSimple(state: SpinnerReducerState, action: PayloadAction<boolean>) {
            return { ...state, showSpinner: action.payload };
        },
        setProgress(state: SpinnerReducerState, action: PayloadAction<IProgressSpinner>) {
            return { ...state, progressSpinner: action.payload };
        },
        stopAll(state: SpinnerReducerState) {
            return { ...state, progressSpinner: { show: false, progress: 0 }, showSpinner: false };
        },
    },
});
// spinnerSlice.actions.set({});

export const { setSimple: setSimpleSpinner, setProgress: setProgressSpinner, stopAll: stopAllSpinners } = spinnerSlice.actions;

export default spinnerSlice.reducer;
