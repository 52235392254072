import { createSlice } from "@reduxjs/toolkit";

// export const actions = {
//     OPEN_ADD_PARTNER_MODAL: "OPEN_ADD_PARTNER_MODAL",
//     CLOSE_ADD_PARTNER_MODAL: "CLOSE_ADD_PARTNER_MODAL",
// } as const;

export interface PartnersUiState {
    openPartnerModal: boolean;
}

const initialState: PartnersUiState = {
    openPartnerModal: false,
};

const partnersUiSlice = createSlice({
    initialState,
    name: "partnersUiSlice",
    reducers: {
        openAddPartnerModal(state) {
            return { ...state, openPartnerModal: true };
        },
        closeAddPartnerModal(state) {
            return { ...state, openPartnerModal: false };
        },
        toggleAddPartnerModal(state) {
            return { ...state, openPartnerModal: !state.openPartnerModal };
        },
    },
});

export const { closeAddPartnerModal, openAddPartnerModal, toggleAddPartnerModal } = partnersUiSlice.actions;

export default partnersUiSlice.reducer;
// eslint-disable-next-line default-param-last
// const partnersUiReducer = (state: PartnersUiState = initialState, action: any) => {
//     const newState = { ...state };
//     switch (action.type) {
//         case actions.OPEN_ADD_PARTNER_MODAL:
//             newState.openPartnerModal = true;
//             return newState;
//         case actions.CLOSE_ADD_PARTNER_MODAL:
//             newState.openPartnerModal = false;
//             return newState;

//         default:
//             return newState;
//     }
// };

// export default partnersUiReducer;
