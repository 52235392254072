import { Button, CardContent, IconButton, styled, Tooltip, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ResponsiveIframe from "../../../../../components/Common/ResponsiveIframe/ResponsiveIframe";

interface IProps {
    url: string;
    category?: string;
    backBtnCallback?: (e) => void;
    isSelected: boolean;
    setOrderList?: any;
    currentProductId?: string | number;
    addToOrdercallback?: (e) => void;
    openExampleReportCB: () => void;
    showAddToOrderBtn?: boolean;
}

const StyledCardContent = styled(CardContent)(() => ({
    backgroundColor: "white",
    padding: "15px",
    display: "flex",
    flexDirection: "column",
    "&:last-child": { paddingBottom: "15px" },
}));

const HeaderContainer = styled("div")(() => ({
    flexBasis: "45%",
    backgroundColor: "#464B52",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0.5rem 0.5rem 0.5rem 1rem",
}));

const HeaderText = styled(Typography)(({ theme }) => ({
    fontSize: "0.75rem",
    textTransform: "uppercase",
    color: theme.palette.common.white,
}));

const TestProductsIframe: FC<IProps> = ({
    url,
    category,
    backBtnCallback,
    isSelected,
    setOrderList,
    currentProductId,
    addToOrdercallback,
    openExampleReportCB,
    showAddToOrderBtn = true,
}) => {
    const [str, setStr] = useState("");

    const handleAddOrRemove = (e) => {
        if (isSelected) {
            if (setOrderList) {
                setOrderList((prev) => {
                    const newOrderList = prev?.filter((item: any) => {
                        if (item?.testProduct?.id === currentProductId) {
                            return false;
                        }
                        return true;
                    });
                    return newOrderList;
                });
            }
        } else if (addToOrdercallback && !isSelected) {
            addToOrdercallback!(e);
        }
    };

    useEffect(() => {
        fetch(url)
            .then((res) => res.text())
            .then((res) => setStr(res));
    }, [url]);

    return (
        <StyledCardContent>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", paddingBottom: "5px" }}>
                <HeaderContainer>
                    <HeaderText variant="h6" align="left" fontWeight={700}>
                        {category || "test.category"}
                    </HeaderText>
                    <Tooltip
                        title={<FormattedMessage id="icon-button.label.tooltip.view-example-results-report" defaultMessage="View an example results report" />}
                        placement="top"
                        arrow
                    >
                        <IconButton onClick={openExampleReportCB}>
                            <UploadFileIcon htmlColor="white" fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </HeaderContainer>

                <div>
                    {showAddToOrderBtn ? (
                        <Button
                            size="medium"
                            variant="outlined"
                            color="primary"
                            disableElevation
                            onClick={handleAddOrRemove}
                            sx={{
                                color: "rgba(40, 40, 40, 0.87)",
                                borderColor: "#FBC84A",
                                borderRadius: "1.5625rem",
                                padding: "1rem 1.25rem",
                                fontSize: "0.875rem",
                                lineHeight: 1.2,
                                "&:hover": {
                                    borderColor: "#FBC84A",
                                    background: "#FBC84A",
                                },
                            }}
                        >
                            {isSelected ? (
                                <FormattedMessage id="templating.header.remove-button" defaultMessage="Remove" />
                            ) : (
                                <FormattedMessage id="templating.header.add-to-order-btn" defaultMessage="Add to order" />
                            )}
                        </Button>
                    ) : null}
                    {backBtnCallback ? (
                        <Button
                            size="medium"
                            variant="text"
                            color="primary"
                            disableElevation
                            onClick={(e) => backBtnCallback(e)}
                            sx={{ margin: "0 5px 0px 0 !important" }}
                        >
                            <FormattedMessage id="templating.header.back-button" defaultMessage="< Back to search" />
                        </Button>
                    ) : null}
                </div>
            </div>
            <div>
                <ResponsiveIframe title="Preview" srcDoc={str} />
            </div>
        </StyledCardContent>
    );
};

export default TestProductsIframe;
