import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CustomFile } from "../utils/appInterfaces/AppInterfaces";

export interface LocalImage {
    id: any;
    imageFiles: CustomFile;
}

export interface LocalImageFiles {
    imageType: string;
    imageFiles: LocalImage[];
}

export interface LocalImageFilesState {
    localImageFiles: LocalImageFiles[];
}

const initialState: LocalImageFilesState = {
    localImageFiles: [],
};

const localImageFilesSlice = createSlice({
    initialState,
    name: "localImageFiles",
    reducers: {
        add(state, action: PayloadAction<LocalImageFiles>) {
            state.localImageFiles.push(action.payload);
            return state;
        },
        removeByType(state, action: PayloadAction<string>) {
            const found = state.localImageFiles.find((imageFiles) => imageFiles.imageType === action.payload);

            if (found) {
                return { ...state, localImageFiles: state.localImageFiles.filter((imageFiles) => imageFiles.imageType !== action.payload) };
            }

            return state;
        },
        removeSingle(state, action: PayloadAction<{ id: any; type: string }>) {
            if (state.localImageFiles.some((e) => e.imageType === action.payload.type)) {
                const filtered = state.localImageFiles
                    .filter((imageFiles) => imageFiles.imageType !== action.payload.type)
                    .map((imageFiles) => ({
                        imageType: imageFiles.imageType,
                        imageFiles: imageFiles.imageFiles.filter((imageFile) => imageFile.id !== action.payload.id),
                    }));

                return { ...state, localImageFiles: filtered };
            }

            return state;
        },
        reset(state) {
            if (state.localImageFiles.length) {
                return { ...state, localImageFiles: [] };
            }

            return state;
        },
    },
});

export default localImageFilesSlice.reducer;
export const localImageFilesActions = localImageFilesSlice.actions;
