import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledMobileViewWrapper = styled("div")(() => ({
    background: "#464B52",
    height: "fit-content",
    padding: "2rem .5rem",
    width: "100%",
    textAlign: "center",
    boxSizing: "border-box",
    "@media(min-width:960px)": { display: "none" },
}));

export const StyledSetupLabel = styled(Typography)(() => ({
    fontSize: "1.2rem",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    "@media(max-width:960px)": {
        justifyContent: "center",
    },
}));
export const StyledSetupLabelInline = styled("span")(() => ({
    position: "relative",
    "&::before": {
        position: "absolute",
        content: '""',
        left: 0,
        top: "-6px",
        width: "37px",
        height: "3px",
        background: "#FBC84A",
    },
}));

export const StyledWelcomeLabel = styled(Typography)(() => ({
    fontSize: "2.5rem",
    color: "#fff",
    marginBottom: "1rem",
    fontWeight: "bold",
}));

export const StyledBrandingMessage = styled(Typography)(() => ({
    color: "#fff",
    fontSize: "1.75rem",
    fontWeight: 300,
    lineHeight: 1.1,
    marginBottom: "1rem",
}));
export const StyledBrandingInfo = styled(Typography)(() => ({
    color: "#fff",
    marginBottom: "1rem",
    fontSize: "1rem",
}));
const commonLabel = {
    fontSize: "1.25rem",
    marginBottom: "1rem",
    fontWeight: 700,
    color: "rgba(40, 40, 40, 0.87)",
};
export const StyledOrgInfo = styled(Typography)(() => ({
    marginTop: "1.2rem",
    ...commonLabel,
}));

export const StyledAdminInfo = styled(Typography)(() => ({
    marginTop: "1rem",
    ...commonLabel,
}));

export const StyledBoxWrapper = styled(Box)(() => ({
    width: "80%",
    margin: "auto",
    textAlign: "center",
}));
