import { styled, TextField } from "@mui/material";

const identifier = "GoogleAutocompleteAddress";

export const classes = {
    initialRoot: `${identifier}-initialRoot`,
    filledRoot: `${identifier}-filledRoot`,
};

export const StyledTextField = styled(TextField)(() => ({
    [`& .${classes.initialRoot}`]: {
        "& .MuiInputLabel-root:not(.Mui-focused):not(.Mui-error)": {
            color: "#999999",
        },
    },
    [`& .${classes.filledRoot}`]: {
        "& .MuiInputLabel-root:not(.Mui-focused):not(.Mui-error)": {
            color: "#464B52",
        },
    },
}));
