import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import unionBy from "lodash/unionBy";
import { ProductGroup } from "../../views/privatePages/ProductGroupManagement/ProductGroupManagementTypes";
import { itemsSortComparator } from "../../views/privatePages/ProductGroupManagement/ProudctGroupManagementUtils";

export interface ProductGroupState {
    productGroups: ProductGroup[];
}

const initialState: ProductGroupState = {
    productGroups: [],
};

const productGroupsReducer = createSlice({
    initialState,
    name: "productGroupsReducer",
    reducers: {
        setProductGroups(_state: ProductGroupState, action: PayloadAction<ProductGroup[]>) {
            return { productGroups: action.payload };
        },
        loadMoreProductGroups(state: ProductGroupState, action: PayloadAction<ProductGroup[]>) {
            return { productGroups: unionBy(state.productGroups, action.payload, "id") };
        },
        addProductGroup(state: ProductGroupState, action: PayloadAction<ProductGroup>) {
            // todo consider sorting to add to the proper place. Sorting should be done as per BE implementation
            return { productGroups: [action.payload, ...state.productGroups].sort(itemsSortComparator) };
        },
        updateSelectedState(_state: ProductGroupState, action: PayloadAction<ProductGroup[]>) {
            return { productGroups: action.payload };
        },
        updateGroupName(state: ProductGroupState, action: PayloadAction<{ id: number; name: string }>) {
            // todo consider sorting to add to the proper place. Sorting should be done as per BE implementation
            const updatedProductGroup = state.productGroups
                .map((item) => {
                    if (item.id === action.payload.id) {
                        return {
                            ...item,
                            name: action.payload.name,
                        };
                    }
                    return { ...item };
                })
                .sort(itemsSortComparator);
            return { productGroups: updatedProductGroup };
        },
        removeProductGroup(state: ProductGroupState, action: PayloadAction<{ id: number; newSelectedItem: number }>) {
            const updatedProductGroup = state.productGroups
                .filter((productGroup) => productGroup.id !== action.payload.id)
                .map((productGroup, index) => {
                    if (index === action.payload.newSelectedItem && productGroup.isDefault) {
                        return { ...productGroup, checked: true };
                    }
                    return { ...productGroup };
                });

            return { productGroups: updatedProductGroup };
        },
    },
});

const selectProductGroups = (state) => state.productGroupsReducer.productGroups;

export const getSelectedProductGroup = createSelector(selectProductGroups, (productGroups) => productGroups.find((item) => item.checked === true));
export const { setProductGroups, addProductGroup, loadMoreProductGroups, updateSelectedState, updateGroupName, removeProductGroup } =
    productGroupsReducer.actions;

export default productGroupsReducer.reducer;
