import { GOOGLE_MEASUREMENT_ID } from "../app.config";

export const initObject = [
    {
        trackingId: GOOGLE_MEASUREMENT_ID || "",
        gaOptions: {
            siteSpeedSampleRate: 100,
        },
    },
];
