import { CardContent, Container, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FormattedMessage } from "react-intl";
import { TestOfferedTemplatingObject } from "../../../utils/appInterfaces/AppInterfaces";
import CollapsibleTabRotatingIcon from "../CollapsibleTabRotatingIcon/CollapsibleTabRotatingIcon";
import HeaderSubheaderTemplate from "../TemplatingHelpers/HeaderSubheaderTemplate";
import { BO_CORE_DEFAULT_VALUE } from "../../../utils/AppUtils";

const PREFIX = "TestOfferedDetails";

const classes = {
    divGridStyle: `${PREFIX}-divGridStyle`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(() => ({
    [`& .${classes.divGridStyle}`]: {
        display: "grid",
        gap: "30px",
        gridAutoFlow: "row",
        overflow: "auto",
        gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
        padding: 0,
        "@media(max-width: 599px)": {
            gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
        },
    },
}));

interface IProps {
    dto: TestOfferedTemplatingObject;
    minimalMode?: boolean;
}

const TestOfferedDetails = ({ minimalMode = false, dto }: IProps) => {
    return (
        <Root>
            <CollapsibleTabRotatingIcon heading={<FormattedMessage id="templating.test-offered.test-offered" defaultMessage="Test Offered" />}>
                <CardContent>
                    <Container classes={{ root: classes.divGridStyle }}>
                        <HeaderSubheaderTemplate
                            heading={<FormattedMessage id="templating.test-offered.test-name" defaultMessage="Test name" />}
                            subheading={dto?.name}
                        />

                        <HeaderSubheaderTemplate
                            heading={<FormattedMessage id="templating.test-offered.lab-order-code" defaultMessage="Lab order code" />}
                            subheading={dto?.labOrderCode}
                        />
                        <div>
                            <HeaderSubheaderTemplate
                                heading={<FormattedMessage id="templating.test-offered.alternative-names" defaultMessage="Alternative names" />}
                                withWrapper={false}
                            />
                            {dto.alternativeNames.length ? (
                                dto.alternativeNames.map((n) => <HeaderSubheaderTemplate subheading={n} withWrapper={false} key={`${n}`} />)
                            ) : (
                                <HeaderSubheaderTemplate subheading="-" withWrapper={false} />
                            )}
                        </div>
                        <HeaderSubheaderTemplate
                            heading={<FormattedMessage id="templating.test-offered.therapeutic-category" defaultMessage="Therapeutic category" />}
                            subheading={dto?.therapeuticCategory || BO_CORE_DEFAULT_VALUE}
                        />
                        {!minimalMode && <HeaderSubheaderTemplate heading="Specialization" subheading={dto?.specialization} />}
                        {dto.isPanel && (
                            <div>
                                <HeaderSubheaderTemplate
                                    heading={<FormattedMessage id="templating.test-offered.panel-components" defaultMessage="Panel components" />}
                                />
                                {dto.testOfferedComponents.map((e) => (
                                    <HeaderSubheaderTemplate subheading={e.name} key={e.id} />
                                ))}
                            </div>
                        )}
                        <div>
                            <HeaderSubheaderTemplate
                                heading={<FormattedMessage id="templating.test-offered.location-availability" defaultMessage="Location availability" />}
                                withWrapper={false}
                            />
                            {dto.locationAvailability?.map((loc) => (
                                <HeaderSubheaderTemplate
                                    subheading={`${loc.name} - Order code: ${loc.locationOrderCode}; capacity: ${loc.locationCapacity}`}
                                    withWrapper={false}
                                    key={loc.id}
                                />
                            ))}
                        </div>
                        {dto.unavailableInRelation?.length ? (
                            <div>
                                <HeaderSubheaderTemplate
                                    heading={<FormattedMessage id="templating.test-offered.unavailable-location" defaultMessage="Unavailable in locations:" />}
                                    withWrapper={false}
                                />
                                {dto.unavailableInRelation.map((loc) => (
                                    <HeaderSubheaderTemplate subheading={loc.name} withWrapper={false} key={loc.id} />
                                ))}
                            </div>
                        ) : null}
                        <div>
                            <HeaderSubheaderTemplate
                                heading={
                                    <FormattedMessage
                                        id="templating.test-offered.unavailable-territories"
                                        defaultMessage="Unavailable in states / regions, territories"
                                    />
                                }
                                withWrapper={false}
                            />
                            {dto.unavailableInRelation?.length ? (
                                dto.unavailableInRelation.map((e) => <HeaderSubheaderTemplate subheading={e.name} withWrapper={false} key={e.id} />)
                            ) : (
                                <HeaderSubheaderTemplate
                                    subheading={<FormattedMessage id="templating.test-offered.no-restrictions" defaultMessage="No restrictions" />}
                                    withWrapper={false}
                                />
                            )}
                        </div>
                    </Container>
                </CardContent>
            </CollapsibleTabRotatingIcon>
            <CollapsibleTabRotatingIcon heading={<FormattedMessage id="templating.test-offered.requirements" defaultMessage="Requirements" />}>
                <CardContent>
                    <Container classes={{ root: classes.divGridStyle }}>
                        <HeaderSubheaderTemplate
                            heading={<FormattedMessage id="templating.test-offered.common-icd-codes" defaultMessage="Common ICD Codes" />}
                            subheading={dto.defaultIcd?.map((e) => e.name).join(", ") || BO_CORE_DEFAULT_VALUE}
                        />
                        <HeaderSubheaderTemplate
                            heading={<FormattedMessage id="templating.test-offered.cpt-billing-codes" defaultMessage="CPT billing codes" />}
                            subheading={dto.cptCode?.map((e) => e.name).join(", ") || BO_CORE_DEFAULT_VALUE}
                        />
                        <HeaderSubheaderTemplate
                            heading={<FormattedMessage id="templating.test-offered.prescription-required" defaultMessage="Prescription always required?" />}
                            subheading={dto.isPrescriptionRequired ? "Yes" : "No"}
                        />
                        {dto.governmentReporting ? (
                            <HeaderSubheaderTemplate
                                heading={
                                    <FormattedMessage
                                        id="templating.test-offered.gov-reporting"
                                        defaultMessage="Gov’t agency reporting (of results) is required."
                                    />
                                }
                            />
                        ) : null}
                        <HeaderSubheaderTemplate
                            heading={<FormattedMessage id="templating.test-offered.has-reflexes" defaultMessage="Has reflexes?" />}
                            subheading={dto.hasReflexes ? "Yes" : "No"}
                        />
                        {dto.hasReflexes && (
                            <>
                                <div>
                                    <HeaderSubheaderTemplate
                                        heading={<FormattedMessage id="templating.test-offered.reflex-tests" defaultMessage="Reflex tests" />}
                                        withWrapper={false}
                                    />
                                    {dto.testOfferedReflexes.map((e) => (
                                        <HeaderSubheaderTemplate subheading={e.name} withWrapper={false} key={e.id} />
                                    ))}
                                </div>
                                <HeaderSubheaderTemplate
                                    heading={<FormattedMessage id="templating.test-offered.reflex-description" defaultMessage="Reflex description" />}
                                    subheading={dto.reflexDescription}
                                />
                            </>
                        )}
                        {dto.reflexesAllowed && (
                            <HeaderSubheaderTemplate
                                heading={<FormattedMessage id="templating.test-offered.allow-reflex-opt-out" defaultMessage="Allow reflex opt-out?" />}
                                subheading={dto.reflexesAllowed ? "Yes" : "No"}
                            />
                        )}
                    </Container>
                </CardContent>
            </CollapsibleTabRotatingIcon>
            <CollapsibleTabRotatingIcon
                heading={<FormattedMessage id="templating.test-offered.collection-specifications" defaultMessage="Collection Specifications" />}
            >
                {dto?.collectionSpecifications?.map((collectionData) => (
                    <CardContent key={collectionData.id}>
                        <Typography variant="subtitle1" sx={{ fontSize: "1.2rem", marginBottom: "1rem", fontWeight: 600, color: "#464B52" }}>
                            {collectionData?.name}
                        </Typography>
                        <Container classes={{ root: classes.divGridStyle }}>
                            <HeaderSubheaderTemplate
                                heading={<FormattedMessage id="templating.test-offered.patient-preparation" defaultMessage="Patient preparation" />}
                                subheading={collectionData.patientPreparation || BO_CORE_DEFAULT_VALUE}
                            />
                            <HeaderSubheaderTemplate
                                heading={<FormattedMessage id="templating.test-offered.specimen-category" defaultMessage="Specimen category" />}
                                subheading={collectionData.specimenCategory || BO_CORE_DEFAULT_VALUE}
                            />
                            <HeaderSubheaderTemplate
                                heading={<FormattedMessage id="templating.test-offered.preferred-specimen" defaultMessage="Preferred specimen" />}
                                subheading={collectionData.preferredSpecimen?.name || BO_CORE_DEFAULT_VALUE}
                            />
                            <HeaderSubheaderTemplate
                                heading={<FormattedMessage id="templating.test-offered.alternate-specimens" defaultMessage="Alternate specimens" />}
                                subheading={collectionData?.alternativeSpecimens?.map((e) => e.name).join(", ") || BO_CORE_DEFAULT_VALUE}
                            />
                            <HeaderSubheaderTemplate
                                heading={<FormattedMessage id="templating.test-offered.minimum-specimen-volume" defaultMessage="Minimum specimen volume" />}
                                subheading={collectionData.minimumVolume || BO_CORE_DEFAULT_VALUE}
                            />
                            <HeaderSubheaderTemplate
                                heading={<FormattedMessage id="templating.test-offered.collection-instructions" defaultMessage="Collection instructions" />}
                                subheading={collectionData.collectionInstructions || BO_CORE_DEFAULT_VALUE}
                            />
                            <HeaderSubheaderTemplate
                                heading={
                                    <FormattedMessage id="templating.test-offered.collection-instructions-link" defaultMessage="Collection instructions link" />
                                }
                                subheading={collectionData.collectionInstructionsLink || BO_CORE_DEFAULT_VALUE}
                            />
                            <HeaderSubheaderTemplate
                                heading={<FormattedMessage id="templating.test-offered.causes-for-rejection" defaultMessage="Causes for rejection" />}
                                subheading={collectionData.causesForRejection || BO_CORE_DEFAULT_VALUE}
                            />
                            <div>
                                <HeaderSubheaderTemplate
                                    heading={
                                        <FormattedMessage id="templating.test-offered.specimen-stability-hours" defaultMessage="Specimen stability hours:" />
                                    }
                                    withWrapper={false}
                                />
                                <HeaderSubheaderTemplate
                                    heading={<FormattedMessage id="templating.test-offered.specimen-stability-room" defaultMessage="Room temperature" />}
                                    subheading={collectionData.roomTemperature || BO_CORE_DEFAULT_VALUE}
                                />
                                <HeaderSubheaderTemplate
                                    heading={<FormattedMessage id="templating.test-offered.specimen-stability-refrigirated" defaultMessage="Refrigerated" />}
                                    subheading={collectionData.refrigeratedTemperature || BO_CORE_DEFAULT_VALUE}
                                />
                                <HeaderSubheaderTemplate
                                    heading={<FormattedMessage id="templating.test-offered.specimen-stability-frozen" defaultMessage="Frozen" />}
                                    subheading={collectionData.frozenTemperature || BO_CORE_DEFAULT_VALUE}
                                />
                            </div>
                            <div>
                                <HeaderSubheaderTemplate
                                    heading={<FormattedMessage id="templating.test-offered.acceptable-container" defaultMessage="Transport and storage" />}
                                />
                                {collectionData?.collectionDevices?.map((collectionDev) => (
                                    <HeaderSubheaderTemplate key={collectionDev.id} subheading={collectionDev.name} />
                                ))}
                            </div>
                        </Container>
                    </CardContent>
                ))}
            </CollapsibleTabRotatingIcon>
            <CollapsibleTabRotatingIcon
                heading={<FormattedMessage id="templating.test-offered.detailed-descriptions" defaultMessage="Detailed Descriptions" />}
            >
                <CardContent>
                    <Container classes={{ root: classes.divGridStyle }}>
                        <HeaderSubheaderTemplate
                            heading={<FormattedMessage id="templating.test-offered.test-description" defaultMessage="Test description" />}
                            subheading={dto?.testDescription}
                        />
                        <HeaderSubheaderTemplate
                            heading={<FormattedMessage id="templating.test-offered.clinical-utility-use" defaultMessage="Clinical utility use" />}
                            subheading={dto?.clinicalUtilityUse}
                        />
                        <HeaderSubheaderTemplate
                            heading={<FormattedMessage id="templating.test-offered.methodology" defaultMessage="Methodology" />}
                            subheading={dto?.methodology}
                        />
                        <HeaderSubheaderTemplate
                            heading={<FormattedMessage id="templating.test-offered.limitations" defaultMessage="Limitations" />}
                            subheading={dto?.limitations}
                        />
                        <div>
                            <HeaderSubheaderTemplate
                                heading={
                                    <FormattedMessage
                                        id="templating.test-offered.expected-turnarround"
                                        defaultMessage="Expected turnaround time after accessioning"
                                    />
                                }
                                withWrapper={false}
                            />
                            <HeaderSubheaderTemplate
                                heading={<FormattedMessage id="templating.test-offered.minimum-hours" defaultMessage="Minimum hours" />}
                                subheading={dto?.expectedTurnaroundTimeHrsMin}
                            />
                            <HeaderSubheaderTemplate
                                heading={<FormattedMessage id="templating.test-offered.maximum-hours" defaultMessage="Maximum hours" />}
                                subheading={dto?.expectedTurnaroundTimeHrsMax}
                            />
                        </div>
                    </Container>
                </CardContent>
            </CollapsibleTabRotatingIcon>
            {!minimalMode && (
                <CollapsibleTabRotatingIcon heading={<FormattedMessage id="templating.test-offered.results" defaultMessage="Results" />}>
                    <CardContent>
                        <Container classes={{ root: classes.divGridStyle }}>
                            {dto?.resultDefinitions?.map((result) => (
                                <div key={result?.id} style={{ border: "1px solid #2828281f", padding: "20px" }}>
                                    <HeaderSubheaderTemplate
                                        heading={<FormattedMessage id="templating.test-offered.result-value-name" defaultMessage="Result value name" />}
                                        subheading={result?.name}
                                        withWrapper={false}
                                    />
                                    <br />
                                    <HeaderSubheaderTemplate
                                        heading={<FormattedMessage id="templating.test-offered.loinc-codes" defaultMessage="LOINC codes" />}
                                        withWrapper={false}
                                    />
                                    {result.loinc.map((l) => (
                                        <HeaderSubheaderTemplate subheading={`${l?.name} | ${l?.description}`} withWrapper={false} key={l?.id} />
                                    ))}
                                    <br />
                                    <HeaderSubheaderTemplate heading="Result type" subheading={result?.resultType} withWrapper={false} />
                                    <br />
                                    {result.resultType === "Qualitative" ? (
                                        <>
                                            <HeaderSubheaderTemplate
                                                heading={<FormattedMessage id="templating.test-offered.lower-range" defaultMessage="Lower range" />}
                                                subheading="-"
                                                withWrapper={false}
                                            />
                                            <br />
                                            <HeaderSubheaderTemplate
                                                heading={<FormattedMessage id="templating.test-offered.upper-range" defaultMessage="Upper range" />}
                                                subheading="-"
                                                withWrapper={false}
                                            />
                                            <br />
                                            <HeaderSubheaderTemplate
                                                heading={<FormattedMessage id="templating.test-offered.uom" defaultMessage="UOM" />}
                                                subheading="-"
                                                withWrapper={false}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <HeaderSubheaderTemplate
                                                heading={<FormattedMessage id="templating.test-offered.lower-range" defaultMessage="Lower range" />}
                                                subheading={result?.lowerRange}
                                                withWrapper={false}
                                            />
                                            <br />
                                            <HeaderSubheaderTemplate
                                                heading={<FormattedMessage id="templating.test-offered.upper-range" defaultMessage="Upper range" />}
                                                subheading={result?.upperRange}
                                                withWrapper={false}
                                            />
                                            <br />
                                            <HeaderSubheaderTemplate
                                                heading={<FormattedMessage id="templating.test-offered.uom" defaultMessage="UOM" />}
                                                subheading={result.uom}
                                                withWrapper={false}
                                            />
                                        </>
                                    )}
                                    <br />
                                    <HeaderSubheaderTemplate
                                        heading={<FormattedMessage id="templating.test-offered.description" defaultMessage="Description" />}
                                        subheading={result?.resultDescription}
                                        withWrapper={false}
                                    />
                                </div>
                            ))}
                        </Container>
                    </CardContent>
                </CollapsibleTabRotatingIcon>
            )}
        </Root>
    );
};

export default TestOfferedDetails;
