import { AxiosResponse } from "axios";
import { getAxiosInstances } from "../../../../utils/AppUtils";
import AxiosActions from "../../AxiosActions";

export const getSupplyProductById = (id: string, config?: any) => {
    return AxiosActions.get(getAxiosInstances().V2, `/health/supply-product/${id}`, { withCredentials: true, ...config }, true, false);
};

export const postNewSupplyProduct = (dto: any): Promise<AxiosResponse> => {
    return AxiosActions.post(getAxiosInstances().V2, `/health/supply-product`, dto, { withCredentials: true }, true);
};

export const updateExistingSupplyProduct = (productId: number, dto: any): Promise<AxiosResponse> => {
    return AxiosActions.put(getAxiosInstances().V2, `/health/supply-product/${productId}`, dto, { withCredentials: true }, true);
};

export const deleteExistingSupplyProduct = (productId: number): Promise<AxiosResponse> => {
    return AxiosActions.delete(getAxiosInstances().V2, `/health/supply-product/${productId}`, { withCredentials: true }, true);
};

export const postNewBOMItem = (dto: any, productId: number): Promise<AxiosResponse> => {
    return AxiosActions.post(getAxiosInstances().V2, `/health/supply-product/${productId}/bom`, dto, { withCredentials: true }, true);
};

export const updateExistingBOMItem = (productId: number, id: number, dto: any): Promise<AxiosResponse> => {
    return AxiosActions.put(getAxiosInstances().V2, `/health/supply-product/${productId}/bom/${id}`, dto, { withCredentials: true }, true);
};

export const deleteExistingBOMItem = (productId: number, id: number): Promise<AxiosResponse> => {
    return AxiosActions.delete(getAxiosInstances().V2, `/health/supply-product/${productId}/bom/${id}`, { withCredentials: true }, true);
};
