import { styled } from "@mui/material/styles";

const PREFIX = "ProductDetailsHeader";

export const classes = {
    categoryWrapper: `${PREFIX}-categoryWrapper`,
    categoryLabelContainer: `${PREFIX}-categoryLabelContainer`,
    categoryLabel: `${PREFIX}-categoryLabel`,
    nameSectionWrapper: `${PREFIX}-nameSectionWrapper`,
    imgContainer: `${PREFIX}-imgContainer`,
    img: `${PREFIX}-img`,
    nameWrapper: `${PREFIX}-nameWrapper`,
    productName: `${PREFIX}-productName`,
    tooltip: `${PREFIX}-tooltip`,
};
export const CategoryWrapper = styled("div")(() => ({
    [`&.${classes.categoryWrapper}`]: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
    },
    [`& .${classes.categoryLabelContainer}`]: {
        flexBasis: "45%",
        backgroundColor: "#464B52",
        padding: "0.5rem 0.5rem 0.5rem 1rem",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    [`& .${classes.categoryLabel}`]: {
        fontSize: "0.75rem",
        textTransform: "uppercase",
        color: "white",
    },
}));

export const NameSectionWrapper = styled("div")(() => ({
    [`&.${classes.nameSectionWrapper}`]: {
        display: "grid",
        gridTemplateColumns: "40% 60%",
        "@media(max-width: 599px)": {
            gridTemplateColumns: "100%",
        },
    },
    [`& .${classes.imgContainer}`]: {
        width: "100%",
        height: 0,
        position: "relative",
        paddingTop: "75%",
    },
    [`& .${classes.img}`]: {
        height: "100%",
        width: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        objectFit: "contain",
    },
    [`& .${classes.nameWrapper}`]: {
        padding: "2rem 1rem 2rem 2.5rem",
        width: "100%",
        overflow: "hidden",
        "@media(max-width: 599px)": {
            paddingLeft: "1rem",
        },
    },
    [`& .${classes.productName}`]: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        "@media(max-width: 599px)": {
            fontSize: "1.25rem",
            fontWeight: 900,
            lineHeight: 1.111,
        },
    },
    [`& .${classes.tooltip}`]: {
        maxWidth: "90%",
        wordWrap: "break-word",
    },
}));
