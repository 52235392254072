/* eslint-disable react/destructuring-assignment */

import { Button, IconButton, Tooltip, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { CategoryWrapper, NameSectionWrapper, classes } from "./ProductDetailsHeaderStyles";

interface IProps {
    minimalMode?: boolean;
    dto?: {
        name?: string;
        category?: string;
        description?: string;
        paymentMethods?: string[];
        imageUrl?: string;
        imageAltName?: string;
    };
    backBtnText?: string | JSX.Element;
    addToOrderBtnText?: string | JSX.Element;
    secondaryBtnText?: string | JSX.Element;
    backBtnCallback?: (e) => void;
    addToOrdercallback?: (e) => void;
    setOrderList?: any;
    currentProductId?: string | number;
    isSelected?: boolean;
    secondaryCallback?: (e) => void;
    openExampleReportCB?: () => void;
    showAddToOrderBtn?: boolean;
}
const ProductDetailsHeader = (props: IProps) => {
    const {
        addToOrderBtnText,
        addToOrdercallback,
        backBtnCallback,
        backBtnText,
        currentProductId,
        dto,
        isSelected,
        minimalMode,
        secondaryBtnText,
        secondaryCallback,
        setOrderList,
        openExampleReportCB,
        showAddToOrderBtn = true,
    } = props;

    const handleAddOrRemove = (e) => {
        if (isSelected) {
            if (setOrderList) {
                setOrderList((prev) => {
                    const newOrderList = prev?.filter((item: any) => {
                        if (item?.testProduct?.id === currentProductId) {
                            return false;
                        }
                        return true;
                    });
                    return newOrderList;
                });
            }
        } else if (addToOrdercallback && !isSelected) {
            addToOrdercallback(e);
        }
    };
    return (
        <>
            {!minimalMode ? (
                <CategoryWrapper className={classes.categoryWrapper}>
                    <div className={classes.categoryLabelContainer}>
                        <Typography variant="h6" align="left" fontWeight={700} className={classes.categoryLabel}>
                            {dto?.category || "test.category"}
                        </Typography>
                        <Tooltip
                            title={
                                <FormattedMessage id="icon-button.label.tooltip.view-example-results-report" defaultMessage="View an example results report" />
                            }
                            placement="top"
                            arrow
                        >
                            <IconButton onClick={openExampleReportCB}>
                                <UploadFileIcon htmlColor="white" fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </div>
                    {backBtnCallback ? (
                        <Button
                            size="medium"
                            variant="text"
                            color="primary"
                            disableElevation
                            onClick={(e) => backBtnCallback(e)}
                            sx={{ margin: "0 5px 0px 0 !important" }}
                        >
                            {backBtnText || <FormattedMessage id="templating.header.back-button" defaultMessage="< Back to search" />}
                        </Button>
                    ) : null}
                </CategoryWrapper>
            ) : null}

            <NameSectionWrapper className={classes.nameSectionWrapper}>
                <div style={{ width: "100%" }}>
                    <div className={classes.imgContainer}>
                        <img src={dto?.imageUrl || "/1h-box.png"} alt={dto?.imageAltName || "/1h-box.png"} className={classes.img} />
                    </div>
                </div>
                <div className={classes.nameWrapper}>
                    <div style={{ maxWidth: "100%", overflow: "hidden" }}>
                        <Tooltip placement="bottom" title={dto?.name || "test.name"} classes={{ popper: classes.tooltip }}>
                            <Typography variant="h4" align="left" fontWeight={900} color="rgba(40, 40, 40, 0.87)" classes={{ h4: classes.productName }}>
                                {dto?.name || "test.name"}
                            </Typography>
                        </Tooltip>
                    </div>
                    <br />

                    <div>
                        <Typography variant="body1" align="left" fontWeight={700} color="rgba(40, 40, 40, 0.87)">
                            <FormattedMessage id="description" defaultMessage="Description" />
                        </Typography>
                        <Typography variant="body1" align="left" color="rgba(40, 40, 40, 0.87)" sx={{ whiteSpace: "pre-wrap" }}>
                            {dto?.description || "test.description"}
                        </Typography>
                        <br />

                        <Typography variant="body1" align="left" fontWeight={700} color="rgba(40, 40, 40, 0.87)">
                            <FormattedMessage id="templating.header.payment-methods" defaultMessage="Available Payment methods:" />
                        </Typography>
                        <Typography variant="body1" align="left" color="rgba(40, 40, 40, 0.87)">
                            {dto?.paymentMethods ? dto?.paymentMethods.map((el) => el).join(", ") : "test.paymentMethods"}
                        </Typography>
                        <br />

                        {showAddToOrderBtn && !minimalMode ? (
                            <div style={{ margin: "0 auto" }}>
                                <Button
                                    size="medium"
                                    variant="outlined"
                                    color="primary"
                                    disableElevation
                                    onClick={handleAddOrRemove}
                                    sx={{
                                        margin: "0 1.875rem 0px 0 !important",
                                        color: "rgba(40, 40, 40, 0.87)",
                                        borderColor: "#FBC84A",
                                        borderRadius: "1.5625rem",
                                        padding: "1rem 1.25rem",
                                        fontSize: "0.875rem",
                                        lineHeight: 1.2,
                                        "&:hover": {
                                            borderColor: "#FBC84A",
                                            background: "#FBC84A",
                                        },
                                    }}
                                >
                                    {isSelected ? (
                                        <FormattedMessage id="templating.header.remove-button" defaultMessage="Remove" />
                                    ) : (
                                        addToOrderBtnText || <FormattedMessage id="templating.header.add-to-order-btn" defaultMessage="Add to order" />
                                    )}
                                </Button>
                                {secondaryCallback && (
                                    <Button
                                        size="medium"
                                        variant="text"
                                        color="primary"
                                        disableElevation
                                        onClick={secondaryCallback}
                                        sx={{ margin: "0 5px 0px 0 !important" }}
                                    >
                                        {secondaryBtnText || (
                                            <FormattedMessage id="templating.header.view-order-journey-btn" defaultMessage="View order journey >" />
                                        )}
                                    </Button>
                                )}
                            </div>
                        ) : null}
                    </div>
                </div>
            </NameSectionWrapper>
        </>
    );
};

export default ProductDetailsHeader;
