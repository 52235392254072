import { keyframes, styled } from "@mui/material";

const myEffect = keyframes`
from { 
    transform: translateY(-200%);
    display: none;
},
to {
    transform: translateY(0);
    display: block;
}
`;

export const AnimatedInItem = styled("div")(({ theme }) => ({
    // @ts-ignore
    animation: `${myEffect} 2000ms ${theme.transitions.easing.easeInOut}`,
}));

export const boxStyle = {
    display: "flex",
    alignItems: "center",
    "& > :not(style)": { margin: "9px 8px 0px 8px", maxWidth: "calc(100% - 18px)" },
    "@media(maxWidth: 768px)": {
        display: "block",
        "& > :not(style)": { margin: "0px", maxWidth: "100%" },
    },
    width: "100%",
};

export const boxForm = {
    alignItems: "flex-start !important",
};

export const boxStyleAutoComplete = {
    display: "flex",
    alignItems: "center",
    "& > :not(style)": { margin: "0px 8px 0px 8px" },
    "@media(max-width: 768px)": {
        display: "block",
        "& > :not(style)": { margin: "0px !important" },
    },
    width: "100%",
};

export const inputLabelStaticStyle = {
    position: "static",
    top: 0,
    transform: "inherit",
    textOverflow: "inherit",
    overflow: "hidden",
    wordWrap: "break-word",
    whiteSpace: "inherit",
    color: "#464B52",
    "&:not(.Mui-focused):not(.MuiFormLabel-filled):not(.Mui-error)": {
        color: "#464B52",
    },
};

export const boxStyleInfoCard = {
    display: "flex",
    borderLeft: "2px solid #99D1D4",
    alignItems: "center",
    margin: "3px 8px 3px 8px",
    "& > :not(style)": { margin: "3px 8px 3px 8px" },
    width: "100%",
};

export const gridInfoLabelAndAttribute = {
    paddingLeft: "6px",
    paddingRight: "12px",
    alignItems: "center",
    maxWidth: "calc(100% - 52px)",
};

export const cardHeaderActionButton = {
    marginLeft: "5px",
    marginBottom: "5px",
};

export const cardHeaderActionWrapper = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-end",
};

export const cardWrapper = {
    borderRadius: "10px",
    boxShadow: "none",
    marginBottom: "18px",
    width: "100%",
    overflow: "inherit",
};

export const cardContentWrapper = {
    padding: "15px 15px 0 15px",
};

export const cardActionWrapper = {
    padding: "0 15px 15px 15px",
};

export const cardActionButton = {
    margin: "0 5px 5px 0 !important",
};

export const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 48 * 4.5 + 8,
        },
    },
};

export const flyInMainWrapper = {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    // justifyContent: "space-between",
    overflow: "hidden",
    paddingLeft: `0 !important`,
    paddingRight: `0 !important`,
};

export const flyInContent = {
    flexGrow: 1,
    overflow: "hidden",
    overflowY: "auto",
    padding: "1rem",
};

export const modalBody = {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    width: "27rem",
    top: "50%",
    left: "50%",
    paddingLeft: `0 !important`,
    paddingRight: `0 !important`,
    background: "white",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    borderRadius: "4px",
    boxShadow: 24,
    "@media(max-width: 500px)": {
        width: "16.25rem",
    },
};
