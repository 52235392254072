/* eslint-disable no-useless-escape */
import { IS_MASKED_SSN_VALUE, MAX_USER_AGE, PATIENT_LEGAL_AGE, PRICE_SCALE_OF_TWO_DIGITS, PROCTOR_LEGAL_AGE, VALID_EMAIL_REGEX } from "./validatorConstants";
import { AutoCompleteOption } from "../utils/appInterfaces/AppInterfaces";
import { DatesFormat } from "../utils/DateFormats";
import {
    convertNewDate,
    formatDateToUTC,
    getDateStringAsDate,
    parseStringDateWithFormat,
    subtractAndCheckIsBefore,
    subtractAndFormat,
    checkDateYears as checkDateYearsMethod,
    getDiffFromTodayAsYears,
    isDateIsValid,
    getCurrentDate,
    checkIfDateStringIsAfter,
} from "../utils/DateTimeUtils";

type ErrorMessage = {
    id: string;
    defaultMessage: string;
};

export const isRequired = (value: string | null | undefined): boolean => {
    return value === "" || value === null || value === undefined;
};

export const isRequiredPhoneNumber = (value: string, dialCode: string): boolean => {
    return value === "" || value.replace(/[^0-9]/g, "") === dialCode;
};

export const isRequiredSelect = (value: AutoCompleteOption | null): boolean => {
    return value === null || value!.label === "";
};

export const isValidRegex = (value: string, pattern: RegExp): boolean => {
    return !pattern.test(value);
};

export const isValidLength = (value: string, minLength: number, maxLength: number): boolean => {
    return value.trim().length < minLength || value.trim().length > maxLength;
};

export const isValidRange = (value: number, minValue: number, maxValue: number): boolean => {
    return value < minValue || value > maxValue;
};

export const isValidEmail = (value: string): { error: boolean; message: ErrorMessage } => {
    let errMessage = {} as ErrorMessage;
    const errRequired = isRequired(value);
    if (errRequired) {
        errMessage = {
            id: "error.email-missing",
            defaultMessage: "Email cannot be empty",
        };
        return { error: errRequired, message: errMessage };
    }
    const errValid = isValidRegex(value, VALID_EMAIL_REGEX);
    if (errValid) {
        errMessage = {
            id: "error.email-field",
            defaultMessage: "Invalid email address",
        };
        return { error: errValid, message: errMessage };
    }
    return { error: errValid, message: errMessage };
};

export const isRequiredAndValidRegex = (
    value: string,
    regExp: RegExp,
    errRequiredMessage: ErrorMessage,
    errValidMessage: ErrorMessage,
): { error: boolean; message: ErrorMessage } => {
    let errMessage = {} as ErrorMessage;
    const errRequired = isRequired(value);
    if (errRequired) {
        errMessage = errRequiredMessage;
        return { error: errRequired, message: errMessage };
    }
    const errValid = isValidRegex(value, regExp);
    if (errValid) {
        errMessage = errValidMessage;
        return { error: errValid, message: errMessage };
    }
    return { error: errValid, message: errMessage };
};
export const isRequiredAndValidLength = (
    value: string,
    minLength: number,
    maxLength: number,
    errRequiredMessage: ErrorMessage,
    errValidMessage: ErrorMessage,
): { error: boolean; message: ErrorMessage } => {
    let errMessage = {} as ErrorMessage;
    const errRequired = isRequired(value);
    if (errRequired) {
        errMessage = errRequiredMessage;
        return { error: errRequired, message: errMessage };
    }
    const errValid = isValidLength(value, minLength, maxLength);
    if (errValid) {
        errMessage = errValidMessage;
        return { error: errValid, message: errMessage };
    }
    return { error: errValid, message: errMessage };
};
export const isValidLengthField = (
    value: string,
    minLength: number,
    maxLength: number,
    errValidMessage: ErrorMessage,
): { error: boolean; message: ErrorMessage } => {
    let errMessage = {} as ErrorMessage;
    const errValid = isValidLength(value, minLength, maxLength);
    if (errValid) {
        errMessage = errValidMessage;
        return { error: errValid, message: errMessage };
    }
    return { error: errValid, message: errMessage };
};

export const checkIsValidURL = (url: string): boolean => {
    const result = url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return result !== null;
};

export const getMinDate = () => subtractAndFormat(MAX_USER_AGE, "y", DatesFormat.newDefaultFormat);

export const checkPersonAge = (selectedDate: string, role: string) => {
    switch (role) {
        case "patient":
            return subtractAndCheckIsBefore(PATIENT_LEGAL_AGE, "y", selectedDate);
        case "proctor":
            return subtractAndCheckIsBefore(PROCTOR_LEGAL_AGE, "y", selectedDate);
        default:
            return undefined;
    }
};

export const checkUserYears = (selectedDate) => {
    const durationFromTodayYears: number = getDiffFromTodayAsYears(selectedDate);
    return durationFromTodayYears >= PATIENT_LEGAL_AGE && durationFromTodayYears <= MAX_USER_AGE;
};

export const checkProctorYears = (selectedDate: Date): boolean => {
    const durationFromTodayYears: number = getDiffFromTodayAsYears(selectedDate);
    return durationFromTodayYears >= PROCTOR_LEGAL_AGE && durationFromTodayYears <= MAX_USER_AGE;
};

export const isValidBirthDate = (date: string | null | Date, _format: string, legalAge?: number): boolean => {
    if (date) {
        const isValidDate = isDateIsValid(date);
        if (isValidDate) {
            let dateAsDate: Date;
            if (typeof date === "string") {
                dateAsDate = getDateStringAsDate(date);
            } else {
                dateAsDate = date as Date;
            }
            if (legalAge && legalAge === PROCTOR_LEGAL_AGE) {
                return checkProctorYears(dateAsDate);
            }
            return checkUserYears(dateAsDate);
        }
        return false;
    }
    return false;
};

export const checkDateRange = (selectedDate: string) => {
    const utc = formatDateToUTC(selectedDate);
    return utc.isValid() && utc.isSameOrAfter(parseStringDateWithFormat(getMinDate(), DatesFormat.newDefaultFormat)) && utc.isSameOrBefore(convertNewDate());
};

export const checkDateYears = (selectedDate: string) => {
    return checkDateYearsMethod(selectedDate, "1000-01-01");
};

export const isValidPrice = (value: string): boolean => {
    return !isValidRegex(value, PRICE_SCALE_OF_TWO_DIGITS);
};

export const isDateInFuture = (item: Date): boolean => {
    const currentDate = getCurrentDate();
    const res = checkIfDateStringIsAfter(item, currentDate);
    return !res;
};

export const isMaskedSSNValue = (value: string): boolean => {
    return !isValidRegex(value, IS_MASKED_SSN_VALUE);
};
