import { Box, Container } from "@mui/material";
import { FC } from "react";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { FormattedMessage } from "react-intl";

interface InvalidShortUrlProps {}

const InvalidShortUrl: FC<InvalidShortUrlProps> = () => {
    return (
        <Container maxWidth={false} sx={{ height: "100%" }}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                }}
            >
                <CancelOutlinedIcon
                    sx={{
                        color: "red",
                        fontSize: "100px",
                        "@media(min-width:250px)": {
                            fontSize: "48px",
                        },
                        "@media(min-width:501px)": {
                            fontSize: "64px",
                        },
                        "@media(min-width: 1200px)": {
                            fontSize: "100px",
                        },
                    }}
                />

                <FormattedMessage id="short-url-handler.label.wrong-code" defaultMessage="Sorry wrong code" />
            </Box>
        </Container>
    );
};

export default InvalidShortUrl;
