import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CONTACT_TYPE } from "../../views/privatePages/PartnershipManagement/components/common/utils";

type TSelectedPartner = { id: number; name: string; isClaimed: boolean; tenantId: number } | null;
type TSelectedContact = { id: number; name: string; type: `${CONTACT_TYPE}`; value: string; phoneNumberRegion: string; notificationsCount: number } | null;
type TSelectedMessage = number | null; // {id: number, name: string} | null;

export type TOutgoingPartnershipInvitationsReducerState = {
    selectedPartner: TSelectedPartner;
    selectedContact: TSelectedContact;
    selectedMessage: TSelectedMessage;
};

const initialState: TOutgoingPartnershipInvitationsReducerState = {
    selectedPartner: null,
    selectedContact: null,
    selectedMessage: null,
};

const slice = createSlice({
    initialState,
    name: "outgoingPartnershipInvitationsSlice",
    reducers: {
        setSelectedPartner(state, action: PayloadAction<TSelectedPartner>) {
            const newState = { ...state, selectedPartner: action.payload };

            return newState;
        },
        setSelectedContact(state, action: PayloadAction<TSelectedContact>) {
            return { ...state, selectedContact: action.payload };
        },
        setSelectedMessage(state, action: PayloadAction<TSelectedMessage>) {
            return { ...state, selectedMessage: action.payload };
        },
    },
});

export const outgoingPartnershipInvitationsActions = slice.actions;
export default slice.reducer;
