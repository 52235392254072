import { Card, CardContent, styled } from "@mui/material";
import { Item } from "./ProductGroupManagementTypes";

// styles
export const modalStyling = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#fff",
    borderRadius: "4px",
    boxShadow: 24,
    p: 4,
};

export const StyledCommonCard = styled(Card)(() => ({
    position: "relative",
    boxShadow: "none !important",
    padding: "1rem .9rem",
    // height: "calc(100vh - 120px)",
    // height: "100%",
    // maxHeight: "70vh",
    // flexGrow: 1,
    overflow: "hidden",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    minHeight: 0,
    "@media(max-width:900px)": { height: "75vh", flex: "unset" },
    // "@media(min-width:1300px)": { height: "calc(100vh - 200px)" },
    // "@media(min-width:1600px)": { height: "calc(100vh - 220px)" },
    // "@media(min-width:1720px)": { height: "calc(100vh - 260px)" },
}));

export const StyledCommonCardContent = styled(CardContent)(() => ({
    overflowY: "auto",
    overflowX: "hidden",
    height: "100%",
    boxSizing: "border-box",
    padding: "0 10px 0 0 !important",
}));

export const StyledListWithCheckBoxWrapper = styled("div")(() => ({
    overflowY: "auto",
    height: "350px",
    "@media(max-width:1024x) and @media(max-height:720px)": { height: "300px !important" },
    "@media(min-width:1300px) and @media(min-height:720px)": { height: "550px" },
    "@media(min-width:1600px)": { height: "500px" },
    "@media(min-width:1720px)": { height: "450px" },
}));

// functions and objects
export const itemsSortComparator = (item1: Item, item2: Item) => {
    const item1Name = item1.name.toLowerCase();
    const item2Name = item2.name.toLowerCase();
    return item1Name.localeCompare(item2Name, undefined, { numeric: true, sensitivity: "base" });
};
