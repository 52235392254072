import { createSlice } from "@reduxjs/toolkit";

export type TCarouselScrollUpdateState = {
    update: boolean;
};

const initialState: TCarouselScrollUpdateState = {
    update: false,
};

const carouselScrollSlice = createSlice({
    initialState,
    name: "carouselScrollUpdateReducer",
    reducers: {
        toggleScroll(state: TCarouselScrollUpdateState) {
            return { ...state, update: !state.update };
        },
    },
});

export const { toggleScroll } = carouselScrollSlice.actions;

export default carouselScrollSlice.reducer;
