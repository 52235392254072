import { useState } from "react";
import { CardHeader, Collapse, IconButton, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { StyledCard, classes } from "./CollapsibleTabRotatingIconStyles";

const CollapsibleTabRotatingIcon = (props: any) => {
    const { heading, icon, children } = props;
    const [open, setOpen] = useState(false);
    const animationStyles = {
        transition: "all 0.35s",
        transformOrigin: "50% 50%",
        transform: `rotate(${open ? 90 : 0}deg)`,
        color: "rgba(40, 40, 40, 0.87)",
    };

    return (
        <StyledCard>
            <CardHeader
                className={classes.border}
                title={
                    <Typography variant="h6" align="left" fontWeight={900} color="rgba(40, 40, 40, 0.87)">
                        {heading}
                    </Typography>
                }
                action={
                    <IconButton onClick={() => setOpen((o) => !o)} size="small" sx={animationStyles}>
                        {icon || <ChevronRightIcon fontSize="large" color="inherit" />}
                    </IconButton>
                }
            />
            <Collapse in={open} className={classes.border}>
                {children}
            </Collapse>
        </StyledCard>
    );
};

export default CollapsibleTabRotatingIcon;
